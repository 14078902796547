export default {
  HOLIDAY: {
    HOLIDAY: "Cuti",
    HOLIDAY1: "Masukkan Cuti",
    DAY: "Hari",
    DATE: "Tarikh",
    CREATE: "Tambah Cuti",
    UPDATE: "Kemaskini Cuti",
    DELETE: "Hapuskan Cuti",

    FORM: {
      TEST: "HAHA!",
      SUCCESS: "Berjaya simpan maklumat cuti",
      FAILED: "Gagal simpan maklumat cuti",
      CLOSED: "Maklumat cuti umum ditutup",
      CANCEL: "Telah dibatalkan",
    },

    MODAL: {
      TITLE: "Uruskan {holiday}",
      CHANGE: "Maklumat cuti umum baharu akan ditukar pada setiap pekerja/staf",
    },
  },
};
