import DepartmentRoutes from "@/modules/company/routers/department/department.router";
import EmployeeRoutes from "@/modules/company/routers/employees/employee.router";
import EmployeeAttendanceRoutes from "@/modules/company/routers/attendance/attendance.router";
import CalendarRoutes from "./calendar/calendar.router";

export default [
  ...DepartmentRoutes,
  ...EmployeeRoutes,
  ...EmployeeAttendanceRoutes,
  ...CalendarRoutes,
];
