export default {
  HOLIDAY: {
    HOLIDAY: "Holiday",
    HOLIDAY1: "Enter Holiday",
    DAY: "Day",
    DATE: "Date",
    CREATE: "Create Holidays",
    UPDATE: "Update Holiday",
    DELETE: "Delete Holiday",

    FORM: {
      TEST: "HAHA!",
      SUCCESS: "Successfully saved holiday",
      FAILED: "Failed to save holiday",
      CLOSED: "Closed holiday form",
      CANCEL: "You have cancelled the action",
    },

    MODAL: {
      TITLE: "Manage {holiday}",
      CHANGE:
        "New employee attendance details with holiday status will be assigned after saving new details",
    },
  },
};
