import Vue from "vue";
import router from "../../router";
import store from "@/core/services/store";
import { LOGOUT } from "@/core/services/store/auth.module";

const ApiInterceptorService = {
  /**
   * Create auth and other interceptors
   */
  create() {
    // this.setAuth();
  },

  /**
   * Assign auth based interceptor.
   *
   * This is to check validity of token.
   */
  setAuth() {
    Vue.axios.interceptors.response.use(
      (res) => {
        console.log(res);
        return res;
      },
      async (error) => {
        console.error(error);
        console.error("Error code: ", error.response.status);

        // Remove Auth token if Error received is 401!
        if (error.response.status === 401) {
          store.dispatch(LOGOUT).then(() => {
            router.push({ name: "login" });
          });

          console.log("Redirected to base.");
        }

        return Promise.reject(error);
      }
    );
  },
};

export default ApiInterceptorService;
