export default {
  GROUP: {
    EMPLOYEEGROUP: "Kumpulan Pekerja",
    CREATEGROUP: "Cipta Kumpulan",
    CONF: "Konfigurasi Kehadiran",
    TIMEIN: "Waktu Masuk",
    TIMECUTIN: "Time In Cut-In",
    TIMEOUT: "Waktu Keluar",
    TIMECUTOUT: "Time Out Cut-Off",
    SAVE: "Simpan",
    UPDATE: "Kemaskini",

    MEMBERSHIP: {
      DELETESUCCESS: "Berjaya mengeluarkan pekerja dari kumpulan",
      ADDSUCCESS: "Berjaya masukkan pekerja dalam kumpulan",
    },

    SCHEDULE: {
      DELETECONFIRM: "Hapuskan jadual?",
      DELETESUCCESS: "Jadual dihapuskan berjaya dihapuskan.",
      DELETEFAILED: "Jadual tidak dapat dihapuskan",
      TABGENERAL: "Umum",
      BASE: "Jadual",
      TYPE: "Jenis",
      TYPEII: "Jenis",
      PROMPT_SELECT: "Sila Pilih jenis hari",
      CLEAR: "Tetapkan semula",
      START: "Mula",
      END: "Akhir",
      DELETE_CANCELLED: "Proses tetapan semula jadual dibatalkan",

      DAYS: {
        MONDAY: "Isnin",
        TUESDAY: "Selasa",
        WEDNESDAY: "Rabu",
        THURSDAY: "Khamis",
        FRIDAY: "Jumaat",
        SATURDAY: "Sabtu",
        SUNDAY: "Ahad",
      },
    },

    GENERAL: {
      NAME: "Nama",
      DESCRIPTION: "Penerangan",
      FORM: {
        SUCCESS: "Berjaya kemaskini maklumat {group}",
        SUCCESS_TITLE: "Berjaya kemaskini",
        FAILED: "Gagal kemaskini maklumat {group}",
        FAILED_TITLE: "Gagal kemaskini",
      },
    },
  },
};
