import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getEmployeeDashboardDailyAttendanceList: (state) => state.items,
  getEmployeeDashboardDailyAttendancePagination: (state) => state.pagination,
  getEmployeeDashboardDailyAttendanceBusy: (state) => state.isBusy,
};

export default {
  getters,
  actions,
  mutations,
  state,
};
