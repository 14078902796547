export default {
  /**
   * @param state
   * @param data
   */
  setEmployeeMonthlyAttendanceList(state, data) {
    state.monthlyList = data.data;
  },

  /**
   * @param state
   */
  clearEmployeeMonthlyAttendanceList(state) {
    state.monthlyList = [];
  },

  /**
   * Set the pagination
   *
   * @param state
   * @param pagination
   */
  setEmployeeMonthlyAttendancePagination(state, pagination) {
    console.log("Set Page", pagination);
    state.pagination = pagination;
  },

  /**
   * Set the current page
   *
   * @param state
   * @param currentPage
   */
  setEmployeeMonthlyAttendancePage(state, currentPage) {
    console.log("Set Page", currentPage);
    state.pagination.current_page = currentPage;
  },

  /**
   * Set Employee Monthly Attendance List date (year,month) filter
   *
   * @param state
   * @param date
   */
  setEmployeeMonthlyAttendanceDateFilter(state, date) {
    console.log("Date Filter", date, state.filter.date);
    state.filter.date = date;
  },

  /**
   *
   * @param state
   * @param filters
   */
  setEmployeeMonthlyAttendanceFilters(state, filters) {
    console.log(filters);
    state.filter = filters;
  },

  /**
   *
   * @param state
   * @param isBusy
   */
  setEmployeeMonthlyAttendanceBusy(state, isBusy) {
    console.log(isBusy);
    state.isBusy = isBusy;
  },

  /**
   * Set the date type.
   *
   * @param state
   * @param type
   */
  setEmployeeMonthlyAttendanceDateType(state, type) {
    console.log(type);
    state.date_type = type;
  },
};
