const state = {
  leave: {},
};

const actions = {
  /**
   *
   * @param context
   * @param payload
   */
  assignEntitledLeaveApplication(context, payload) {
    console.log(payload.name);
    context.commit("setEntitledLeaveApplicationModal", payload);
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param leave
   */
  setEntitledLeaveApplicationModal(state, leave) {
    console.log("Hmm", leave);
    state.leave = leave;
  },
};

const getters = {
  getAssignEntitledLeaveApplication: (state) => state.leave,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
