import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getEmployeeAwayIndividualEntitlementList: (state) => state.items,
  getEmployeeAwayIndividualEntitlementListPagination: (state) =>
    state.pagination,
  getEmployeeAwayIndividualEntitlementForm: (state) => state.form,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
