import ApiService from "@/core/services/api.service";

export default {
  /**
   * Fetch Employee dashboard count.
   * @param context
   * @returns {*}
   */
  fetchEmployeeDashboardCount(context) {
    ApiService.setHeader();

    return ApiService.query("company/dashboard/employee").then((res) => {
      console.log(res);
      context.commit("setEmployeeDashboardCount", res.data.count);
    });
  },
};
