export default [
  {
    path: "company/employee/attendance/monthly/pdf",
    name: "employeeMonthlyAttendancePdf",
    component: () =>
      import(
        "../../../company/components/attendance/monthly/pdf/EmployeeMonthlyAttendancePdfPage"
      ),
  },
  {
    path: "company/employee/attendance/yearly/pdf",
    name: "employeeYearlyAttendancePdf",
    component: () =>
      import(
        "../../../company/components/attendance/yearly/pdf/EmployeeYearlyAttendancePdfPage"
      ),
  },
];
