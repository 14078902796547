export default [
  // Settings
  {
    text: "SIDEBAR.SETTINGS",
    type: "menu-section",
    url: {},
    guard: {
      permissions: ["manage.employee"],
    },
  },
  {
    text: "SIDEBAR.COMPANY",
    type: "menu-nav-parent",
    guard: {
      permissions: ["manage.employee"],
    },
    url: {
      path: "settings/company",
    },
    icon: "/media/svg/icons/Home/Home.svg",
    children: [
      {
        text: "SIDEBAR.REGISTRATION",
        type: "menu-nav",
        url: {
          path: "/settings/company/register",
        },
        icon: "/media/svg/icons/Home/Building.svg",
        guard: {
          permissions: ["manage.employee"],
        },
      },
      {
        text: "SIDEBAR.HOLIDAY",
        type: "menu-nav",
        url: {
          name: "settingsHoliday",
        },
        icon: "/media/svg/icons/Food/Coffee1.svg",
        guard: {
          permissions: ["manage.employee"],
        },
      },
    ],
  },
];
