import Employee from "./assignment/employee_assignment.router";

const EmployeeAwayAssignmentPage = () =>
  import("@/modules/company/components/away/assignment/EmployeeAwayAssignment");

export default [
  {
    path: "company/away/assignment",
    component: EmployeeAwayAssignmentPage,
    name: "employeeAwayAssignment",
  },
  ...Employee,
];
