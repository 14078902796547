import ApiService from "@/core/services/api.service";
import state from "./state";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";

export default {
  /**
   * Fetch Employee Yearly Attendance
   *
   * @param context
   */
  async fetchEmployeeYearlyAttendanceList(context) {
    try {
      ApiService.setHeader();
      context.commit("setEmployeeYearlyAttendanceBusy", true);

      const res = await ApiService.query("employee/attendance/yearly", {
        params: {
          "page[size]": state.size,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[base]": state.filter?.base,
          date: state.filter.date,
        },
      });

      console.log("Received ", res);
      context.commit("setEmployeeYearlyAttendanceList", res.data.data);
      context.commit(
        "setEmployeeYearlyAttendancePagination",
        res.data.meta.pagination
      );

      return res;
    } catch (error) {
      console.error(error);

      throw Error("Failed.");
    } finally {
      context.commit("setEmployeeYearlyAttendanceBusy", false);
    }
  },

  /**
   * Download yearly report
   *
   * @param context
   * @param payload
   * @returns {Promise<AxiosResponse<*>>}
   */
  async downloadEmployeeYearlyAttendanceReport(context, payload) {
    console.log(payload);

    try {
      ApiService.setHeader();

      const response = await ApiService.query(
        "employee/attendance/report/yearly",
        {
          responseType: "blob",
          params: {
            type: payload.type ?? 1,
            date: state.filter?.date ?? 1,
          },
        }
      );

      AppReportDownloadService.processDownload(
        response.data,
        `Employee Yearly Report - ${payload.date}`
      );

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   * Change list filter date
   *
   * @param context
   * @param payload
   */
  changeEmployeeYearlyAttendanceListDate(context, payload) {
    const date = payload.date;
    if (date !== null) {
      context.commit("setEmployeeYearlyAttendanceDate", date);
    }
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeEmployeeYearlyAttendanceBaseFilter(context, payload) {
    const filters = state.filter;

    filters.base = payload.name;

    context.commit("setEmployeeYearlyAttendanceFilters", filters);
  },
};
