import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getEmployeeAwayApplicationApprovalList: (state) => state.items,
  getEmployeeAwayApplicationApprovalPagination: (state) => state.pagination,
  setEmployeeAwayApplicationApprovalPage: (state) =>
    state.pagination.current_page,
};

export default {
  actions,
  mutations,
  getters,
  state,
};
