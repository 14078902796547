export default {
  form: {
    leave: {
      name: "Dummy",
    },
  },
  isBusy: false,
  invalidation: {

  }
};
