export default {
  items: [],
  pagination: {},
  size: 30,
  filter: {
    search: null,
  },
  filters: {
    base: null,
  },
  isBusy: false,
};
