import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getEmployeeMonthlyAttendanceList: (state) => state.monthlyList,
  getEmployeeMonthlyAttendanceMeta: (state) => state.pagination,
  getEmployeeMonthlyAttendanceBusy: (state) => state.isBusy,
  getEmployeeMonthlyAttendanceDate: (state) => state.filter.date,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
