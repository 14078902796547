export default {
  Employee_Personal: {
    Profile: {
      Modal: {
        Title: "Maklumat Gambar Peribadi",
      },
    },

    Tooltip: {
      Profile: "Muatnaik Gambar",
    },
  },
};
