import ApiService from "@/core/services/api.service";

export default {
  /**
   *
   * @param context
   * @param payload
   */
  fetchEmployeeEducationDetailsList(context, payload) {
    ApiService.setHeader();
    return ApiService.query("employee/detail/education", {
      params: {
        "page[number]": 1,
        "page[size]": 100,
        id: payload.id,
      },
    }).then((res) => {
      console.log(res);
      context.commit("setEmployeeEducationDetailsList", res.data.data);
      context.commit(
        "setEmployeeEducationDetailsListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Submit employee education details form.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  submitEmployeeEducationDetailsForm(context, payload) {
    ApiService.setHeader();
    return ApiService.post("employee/detail/education", payload.form).then(
      (res) => {
        console.log(res);
        return res;
      }
    );
  },

  /**
   * Update Employee Education Details form.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  updateEmployeeEducationDetailsForm(context, payload) {
    ApiService.setHeader();
    return ApiService.post(
      "employee/detail/education/update",
      payload.form
    ).then((res) => {
      console.log(res);
      return res;
    });
  },

  /**
   * Delete.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  deleteEmployeeEducationDetailsForm(context, payload) {
    ApiService.setHeader();
    return ApiService.delete("employee/detail/education", {
      params: {
        id: payload.id,
      },
    }).then((res) => res);
  },
};
