import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   * Fetch individual Employee leave list.
   *
   * @param context
   * @returns {*}
   */
  fetchIndividualEmployeeLeaveMonthlyList(context) {
    ApiService.setHeader();

    return ApiService.query("company/leave/application/individual/monthly", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
        date: state.filter.date,
        id: state.filter.employeeId,
      },
    }).then((res) => {
      console.log(res);
      context.commit("setIndividualEmployeeLeaveList", res.data.data);
      context.commit(
        "setIndividualEmployeeLeaveListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Fetch individual staff leave list.
   *
   * @param context
   * @returns {*}
   */
  fetchIndividualEmployeeLeaveYearlyList(context) {
    ApiService.setHeader();

    return ApiService.query("company/leave/application/individual/yearly", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
        date: state.filter.date,
        id: state.filter.employeeId,
      },
    }).then((res) => {
      console.log(res);
      context.commit("setIndividualEmployeeLeaveList", res.data.data);
      context.commit(
        "setIndividualEmployeeLeaveListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Change the filter.
   *
   * @param context
   * @param payload
   */
  changeIndividualEmployeeLeaveListFilter(context, payload) {
    const filters = {
      date: payload.date ?? dayjs().format(),
      employeeId: payload.employeeId ?? null,
    };

    context.commit("setIndividualEmployeeLeaveListFilters", filters);
  },
};
