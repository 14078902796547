import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<void>}
   */
  async fetchEmployeeDashboardDailyAttendanceList(context, payload) {
    try {
      ApiService.setHeader();
      context.commit("setEmployeeDashboardDailyAttendanceBusy", true);

      console.log("Daily Date ", dayjs().format());

      const res = await ApiService.query(
        "company/dashboard/employee/attendance/daily/detailed",
        {
          params: {
            "page[size]": state.size,
            "page[number]": state.pagination.current_page ?? 1,
            date: dayjs().format(),
            id: payload.id,
          },
        }
      );

      context.commit("setEmployeeDashboardDailyAttendanceList", res.data.data);
      context.commit(
        "setEmployeeDashboardDailyAttendancePagination",
        res.data.meta.pagination
      );
    } catch (e) {
      console.error(e);
    } finally {
      context.commit("setEmployeeDashboardDailyAttendanceBusy", false);
    }
  },
};
