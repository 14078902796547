/**
 * Defines a template of id and its name of day.
 *
 * @type {({name: string, id: number}|{name: string, id: number})[]}
 */
const template = [
  { id: 1, name: "monday" },
  { id: 2, name: "tuesday" },
  { id: 3, name: "wednesday" },
  { id: 4, name: "thursday" },
  { id: 5, name: "friday" },
  { id: 6, name: "saturday" },
  { id: 7, name: "sunday" },
];

/**
 * Convert Schedule form to days array
 * @param form
 * @returns {[]}
 */
function convertGroupForm(form) {
  console.log("Converting", form);

  let days = [];
  template.forEach((value) => {
    console.log(value);

    if (form[value.name].day_type !== null) {
      days.push({
        id: value.id,
        type: form[value.name].day_type,
        time_in: form[value.name].time_in,
        time_out: form[value.name].time_out,
      });
    }
  });

  return days;
}

/**
 * Create and assign empty form.
 *
 * @link https://stackoverflow.com/questions/1168807/how-can-i-add-a-key-value-pair-to-a-javascript-object
 * @returns {{}}
 */
function generateEmptyForm() {
  let form = {};
  template.forEach((value) => {
    console.log(value);

    form[value.name] = {};

    form[value.name]["time_in"] = "";
    form[value.name]["time_out"] = "";
    form[value.name]["day_type"] = null;

    console.log(form[value.name]);
  });

  return form;
}

export default {
  convertGroupForm,
  generateEmptyForm,
};
