import VALIDATION from "./validation.ms";

export default {
  COMMON: {
    TOOLTIP: {
      REFRESH: "Segar semula",
    },

    Actions: {
      Close: "Tutup",
      Search: "Carian",
    },

    Upload: {
      Image: {
        Success:
          "Berjaya muatnaik gambar dan boleh digunakan untuk peranti muka.",
        Success_Title: "Image dimuatnaik",
      },
    },

    Download: {
      Pending: "Sedang muat turun fail",
      Success: "Muat turun berjaya",
      Failed: "MUat turun gagal"
    },

    Table: {
      Actions: {
        Base: "Tindakan",
        Reset_Filters: "Tetapkan Semula Filters",
        Export: "Export - {type}",
        Export_Excel: "Eksport - Excel",
        Export_PDF: "Eksport - Excel",
      },
    },
  },

  ...VALIDATION,
};
