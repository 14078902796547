export default {
  /**
   * Set Employee Education Details List
   *
   * @param state
   * @param items
   */
  setEmployeeEducationDetailsList(state, items) {
    console.log("Items are: ", items);
    state.items = items;
  },

  /**
   * Set Employee Education Details Pagination
   *
   * @param state
   * @param pagination
   */
  setEmployeeEducationDetailsListPagination(state, pagination) {
    console.log("Pagination are :", pagination);
    state.pagination = pagination;
  },

  /**
   * Set current page
   *
   * @param state
   * @param currentPage
   */
  setEmployeeEducationDetailsListPage(state, currentPage) {
    console.log("Current Page : ", currentPage);
    state.pagination.current_page = currentPage;
  },
};
