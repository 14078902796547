import ApiService from "@/core/services/api.service";
import Vue from "vue";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";

const state = {
  items: [],
  errors: null,
  size: 20,
  pagination: {},
  isBusy: {
    fetchForm: true,
  },
  busyStore: false,
  filters: {
    name: null,
    serial: null,
    purchase_date: null,
  },
};

const actions = {
  /**
   * Get facial device list.
   *
   * @param context
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getFacialDevices(context) {
    try {
      context.commit("changeFDListBusy", true);
      ApiService.setHeader();
      const response = await Vue.axios.get("device/facial", {
        params: {
          "page[number]": state.pagination.current_page ?? 1,
          "page[size]": state.size ?? 10,
          "filter[name]": state.filters?.name,
          "filter[purchase_date]": state.filters?.purchase_date,
        },
      });

      console.log(response);
      context.commit("setFacialDevices", response.data.data);
      context.commit(
        "setFacialDevicesPagination",
        response.data.meta.pagination
      );
      return response;
    } catch (e) {
      console.log(e);
    } finally {
      context.commit("changeFDListBusy", false);
    }
  },

  /**
   * Fetch facial device report for the organisation.
   *
   * @returns {Promise<void>}
   */
  async fetchFacialDeviceReport(context, payload) {
    ApiService.setHeader();

    const response = await ApiService.query("device/facial/report", {
      responseType: "blob",
      params: {
        "filter[name]": state.filters?.name,
        "filter[purchase_date]": state.filters?.purchase_date,
        type: payload?.type,
      },
    });

    if (response) {
      AppReportDownloadService.processDownload(
        response.data,
        "Facial Devices - "
      );
    }
  },

  emptyFacialDevices(context) {
    context.commit("setFacialDevices", []);
  },

  /**
   * Change name filter.
   *
   * @param context
   * @param payload
   */
  changeFDListNameFilter(context, payload) {
    let filters = state.filters;
    filters.name = payload.name;

    context.commit("setFDListFilters", filters);
  },

  /**
   * Change date
   *
   * @param context
   * @param payload
   */
  changeFDListPurchaseDateFilter(context, payload) {
    let filters = state.filters;
    filters.purchase_date = payload.date;

    context.commit("setFDListFilters", filters);
  },
};

const mutations = {
  /**
   * Set Facial Devices Response
   *
   * @param state
   * @param response
   */
  setFacialDevices(state, response) {
    console.log("Facial Devices:", response);
    state.items = response;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setFacialDevicesPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setFacialDevicesPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Set Error
   *
   * @param state
   * @param error
   */
  setFacialDevicesError(state, error) {
    console.error(error);
    state.errors = error;
  },

  /**
   * Set filters.
   *
   * @param state
   * @param filters
   */
  setFDListFilters(state, filters) {
    console.log(filters);

    state.filters = filters;
  },
  changeFDListBusy(state, busy) {
    state.busyStore = busy;
  },
};

const getters = {
  getFacialDevices: (state) => state.items,
  getFacialDevicesPagination: (state) => state.pagination,
  getFacialDevicesBusy: (state) => state.busyStore,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
