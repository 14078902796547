import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getEmployeeEducationDetailsList: (state) => state.items,
  getEmployeeEducationDetailsListPagination: (state) => state.pagination,
  getEmployeeEducationDetailsPage: (state) => state.pagination.current_page,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
