import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   * Fetch employee admin list
   *
   * @param context
   * @returns {*}
   */
  async fetchEmployeeAdminList(context) {
    try {
      ApiService.setHeader();
      context.commit("setEmployeeAdminListBusy", true);

      const response = await ApiService.query("employee/privilege", {
        params: {
          "page[size]": 15,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[base]": state.filters?.base,
          role: state.filters?.role ?? 1,
        },
      });

      context.commit("setEmployeeAdminList", response.data.data);
      context.commit(
        "setEmployeeAdminListPagination",
        response.data.meta.pagination
      );

      return response;
    } catch (err) {
      console.error(err);
    } finally {
      context.commit("setEmployeeAdminListBusy", false);
    }
  },

  /**
   * Change admin list name filter.
   *
   * @param context
   * @param payload
   */
  changeEmployeeAdminListBaseFilter(context, payload) {
    const filters = state.filters;

    filters.base = payload.base;
    context.commit("setEmployeeAdminListFilters", filters);
  },

  changeEmployeeAdminListRoleFilter(context, payload) {
    const filters = state.filters;

    filters.role = payload.role;
    context.commit("setEmployeeAdminListFilters", filters);
  },
};
