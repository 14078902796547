import EmployeeRoutes from "./employee_attendance.router";
import EmployeePdfRoutes from "./emp_attendance_pdf.router";

const EmployeeAttendancePage = () =>
  import("@/modules/company/components/attendance/EmployeeAttendancePage");

export default [
  {
    path: "company/employee/attendance",
    component: EmployeeAttendancePage,
    children: [...EmployeeRoutes],
  },
  {
    path: "company/employee/attendance/yearly/export",
    name: "employeeYearlyAttendanceExport",
    component: () =>
      import(
        "../../../company/components/attendance/export/yearly/EmployeeExportYearlyAttendancePage"
      ),
  },
  {
    path: "company/employee/attendance/monthly/export",
    name: "employeeMonthlyAttendanceExport",
    component: () =>
      import(
        "../../../company/components/attendance/export/monthly/EmployeeExportMonthlyAttendancePage"
      ),
  },
  {
    path: "company/employee/attendance/yearly/individual",
    name: "individualYearlyAttendance",
    component: () =>
      import(
        "../../../company/components/attendance/yearly/individual/IndividualEmployeeAttendanceYearlyPage"
      ),
  },
  ...EmployeePdfRoutes,
];
