import DailyListStore from "./daily/daily_attendance.module";
import MonthlyListStore from "./monthly/monthly_attendance.store";
import IndividualMonthlyListStore from "./emp_individual_attendance_list.store";
import ManageDailyAttendanceStore from "./attendance_manage_daily.store";
import YearlyAttendance from "./yearly/attendance_yearly.store";
import AttendanceExport from "./export/attendance_export.store";
import OldDailyStore from "./attendance_list_daily.store";
import MainManageAttendance from "./manage/manage_attendance.store";

export default {
  modules: {
    DailyListStore,
    MonthlyListStore,
    IndividualMonthlyListStore,
    ManageDailyAttendanceStore,
    YearlyAttendance,
    AttendanceExport,
    OldDailyStore,
    MainManageAttendance,
  },
};
