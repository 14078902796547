export default [
  {
    path: "company/away/employee/assignment",
    name: "employeeIndividualAwayAssignment",
    component: () =>
      import(
        "../../../../company/components/away/assignment/employee/EmployeeIndividualAwayAssignmentPage"
      ),
  },
];
