export default {
  id: 1,
  items: [],
  pagination: {},
  size: 30,
  isBusy: false,
  filters: {
    base: null,
    role: 1,
  },
};
