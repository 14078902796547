import ApiService from "@/core/services/api.service";
import state from "./state";

export default {
  /**
   * Fetch personal leave entitlement.
   *
   * @param context
   * @returns {*}
   */
  fetchPersonalAwayEntitlementList(context) {
    ApiService.setHeader();

    context.commit("setPersonalAwayEntitlementListBusy", true);
    return ApiService.query("company/away/personal/entitlement", {
      params: {
        "page[size]": 15,
        "page[number]": state.pagination.current_page ?? 1,
      },
    })
      .then((response) => {
        context.commit("setPersonalAwayEntitlementList", response.data.data);
        context.commit(
          "setPersonalAwayEntitlementListPagination",
          response.data.meta.pagination
        );
      })
      .finally(() =>
        context.commit("setPersonalAwayEntitlementListBusy", true)
      );
  },

  /**
   * Submit employee personal leave application form.
   *
   * @param context
   * @param payload
   */
  submitPersonalAwayApplicationForm(context, payload) {
    ApiService.setHeader();

    return ApiService.post(
      "company/away/personal/application",
      payload.form
    ).then((res) => res);
  },

  /**
   * Fetch personal leave entitlement form.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  fetchPersonalAwayEntitlementForm(context, payload) {
    ApiService.setHeader();

    return ApiService.query("company/away/personal/entitlement/form", {
      params: {
        id: payload.id,
      },
    }).then((res) => {
      context.commit("setPersonalAwayEntitlementForm", res.data);
      return res;
    });
  },
};
