const LeaveAssignmentPage = () =>
  import(
    "@/modules/company/components/leave/assignment/CompanyLeaveAssignmentPage"
  );
const EmployeeLeaveAssignmentPage = () =>
  import(
    "@/modules/company/components/leave/assignment/employee/CompanyEmployeeLeaveAssignmentPage"
  );

export default [
  {
    path: "/company/leave/assignment",
    name: "companyLeaveAssignment",
    component: LeaveAssignmentPage,
  },
  {
    path: "/company/leave/assignment/employee",
    component: EmployeeLeaveAssignmentPage,
  },
];
