import ApiService from "@/core/services/api.service";
import dayjs from "dayjs";

const state = {
  list: [],
  employee: null,
  isBusy: {
    create: false,
    list: false,
  },
  pagination: {},
  filters: {
    date_type: 1,
    from_date: dayjs().format(),
    to_date: dayjs().format(),
    date: dayjs().format(),
  },
  date: new Date(),
};

const actions = {
  /**
   * @param context
   * @param employee
   */
  assignIndividualEmployeeAttendance(context, employee) {
    context.commit("setAssignedIndividualEmployeeAttendance", employee);
  },

  /**
   * Change date_type filter.
   *
   * @param context
   * @param payload
   */
  changeIndividualEmployeeAttendanceDateType(context, payload) {
    context.commit(
      "setAssignedIndividualEmployeeAttendanceDateType",
      payload.type
    );
  },

  /**
   * Change payload of specific code.
   *
   * @param context
   * @param payload
   */
  changeIndividualEmployeeAttendanceSpecificDate(context, payload) {
    context.commit(
      "setAssignedIndividualEmployeeAttendanceSpecificDate",
      payload.date
    );
  },

  /**
   * Change payload of specific code.
   *
   * @param context
   * @param payload
   */
  changeIndividualEmployeeAttendanceDateRange(context, payload) {
    context.commit("setAssignedIndividualEmployeeAttendanceDateRange", payload);
  },

  /**
   * @param context
   * @param date
   */
  assignIndividualEmployeeAttendanceDate(context, date) {
    context.commit("setAssignedIndividualEmployeeAttendanceDate", date);
  },

  /**
   * @param context
   */
  async fetchIndividualEmployeeAttendance(context) {
    console.log("Fetching");

    try {
      ApiService.setHeader();

      let dateFilter;

      if (state.filters?.date_type === 1) {
        dateFilter = { date: dayjs(state.date).format() };
      } else {
        dateFilter = {
          to_date: state.filters?.to_date,
          from_date: state.filters?.from_date,
        };
      }

      const response = await ApiService.query(
        "employee/attendance/monthly/individual",
        {
          params: {
            date_type: state.filters?.date_type ?? 1,
            id: state.employee.id,
            ...dateFilter,
            "page[size]": 15,
            "page[number]": state.pagination?.current_page ?? 1,
          },
        }
      );

      context.commit("setIndividualEmployeeAttendance", response.data.data);
      context.commit(
        "setAssignedIndividualEmployeeAttendancePagination",
        response.data.meta.pagination
      );
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};

const mutations = {
  /**
   * Set individual employee attendance.
   *
   * @param state
   * @param employee
   */
  setAssignedIndividualEmployeeAttendance(state, employee) {
    console.log("Selected employee is", employee);
    state.employee = employee;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setAssignedIndividualEmployeeAttendancePagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param page
   */
  setAssignedIndividualEmployeeAttendancePage(state, page) {
    state.pagination.current_page = page;
  },

  /**
   * Set assigned specific date filter.
   *
   * @param state
   * @param date
   */
  setAssignedIndividualEmployeeAttendanceSpecificDate(state, date) {
    console.log(date);

    state.filters.date = date;
  },

  /**
   * Set date type.
   *
   * @param state
   * @param type
   */
  setAssignedIndividualEmployeeAttendanceDateType(state, type) {
    console.log("Type: ", type);

    // Type 1 or 2.
    state.filters.date_type = type;
  },

  /**
   * Set assigned specific date range filter.
   *
   * @param state
   * @param range
   */
  setAssignedIndividualEmployeeAttendanceDateRange(state, range) {
    console.log(range);

    state.filters.from_date = range.from_date;
    state.filters.to_date = range.to_date;
  },

  /**
   * Set date.
   *
   * @param state
   * @param date
   */
  setAssignedIndividualEmployeeAttendanceDate(state, date) {
    console.log(date);
    state.date = date;
  },

  /**
   * @param state
   * @param data
   */
  setIndividualEmployeeAttendance(state, data) {
    console.log("List of attendance", data);
    state.list = data;
  },
};

const getters = {
  getIndividualEmployeeAttendanceList: (state) => state.list,
  getAssignedIndividualEmployeeAttendance: (state) => state.employee,
  getAssignedIndividualEmployeeAttendanceDate: (state) => state.date,
  getAssignedIndividualEmployeeAttendanceFilters: (state) => state.filters,
  getAssignedIndividualEmployeeAttendancePagination: (state) =>
    state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
