import EMPLOYEE from "./ms/employees/employees.ms";
import COMPANY from "./ms/company/company.ms";
import LEAVE from "./ms/attendance/leave/leave.ms";
import COMMON from "./ms/common/common.ms";

// MALAY/MALAYSIAN
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },

  MENU: {
    NEW: "baharu",
    ACTIONS: "Tindakan",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "Senarai",
      PENDING: "Belum Diproses",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
  DEVICE: {
    FACIAL: {
      PAGE: {
        HEADER: "Peranti Muka",
        SUBTITLE: "Senarai Peranti Muka",
        BUTTON: "Daftar",
        DEVICE: "Peranti",
        MODEL: "Model",
        SERIALNUMBER: "Nombor Siri",
        ACTIONS: "Tindakan",
        HEADERFORM: "Daftar Peranti Muka",
        DEVICENAME: "Nama Peranti",
        DEVICENAME1: "Masukkan Nama Peranti",
      },
    },
  },

  ATTENDANCE: {
    HEADER: "Kehadiran Pekerja",
    SUBTITLE: "Rekod Kehadiran Pekerja Syarikat Anda",
    BUTTON: "Muat Semula",
    IN: "Masuk",
    OUT: "Kali Terakhir",
    ACTIONS: "Tindakan",
    TEMPERATURE: "Suhu",
    TODAY: "Kehadiran Hari Ini",
    PUNCHIN: "Waktu Masuk",
    PUNCHOUT: "Waktu Keluar",
    DAILY: "Harian",
    MONTHLY: "Bulanan",
    MONTH: "Kehadiran Bulanan Anda",
    DATE: "Tarikh",
    HOVER: {
      CLOCK: "Rekod Jam",
      MODIFY: "Tukar Kehadiran",
      TIME: "Tukar Masa Kehadiran",
      MONTHLY: "Butir Kehadiran Bulanan",
      YEARLY: "Butir Kehadiran Tahunan",
    },
  },
  COMPANY: {
    REGISTRATION: {
      GENERAL: "Umum",
      CONTACT: "Kenalan",
      RESET: "Tetap Semula",
      SUBMIT: "Simpan",
    },
  },

  COMPANYFORM: {
    COMPANYFORM1: "Butiran Syarikat",
    COMPANYFULLNAME1: "Nama Penuh Syarikat:",
    COMPANYFULLNAME2: "Masukkan Nama Penuh Syarikat",
    COMPANYFULLNAME3: "Nama Penuh Syarikat Berdasarkan Borang Pendaftaran.",
    COMPANYTYPE: "Jenis Syarikat:",
    COMPANYREGNUM: "Nombor Pendaftaran Syarikat:",
    COMPANYREGNUM1: "Masukkan Nombor Pendaftaran Syarikat",
    COMPANYNAME: "Nama Syarikat:",
    COMPANYNAME1: "Masukkan Nama Syarikat ",
    COMPANYDESC: "Latar Belakang Syarikat:",
    COMPANYDESC1: "Masukkan Latar Belakang",
    COMPANYEMAIL: "Emel Syarikat:",
    COMPANYEMAIL1: "Masukkan Emel",

    COMPANYPHONE: "No. Tel Syarikat:",
    COMPANYPHONE1: "Masukkan No. Tel:",
    COMPANYADDRESS1: "Alamat Syarikat 1:",
    COMPANYADDRESS12: "Masukkan Alamat",
    COMPANYADDRESS13: "Diperlukan.",
    COMPANYADDRESS2: "Alamat Syarikat 2:",
    COMPANYADDRESS22: "Masukkan Alamat",
    COMPANYADDRESS23: "Tidak diwajibkan.",
    COMPANYADDRESS3: "Alamat Syarikat 3:",
    COMPANYADDRESS32: "Masukkan Alamat",
    COMPANYADDRESS33: "Tidak diwajibkan.",
    COMPANYLOC1: "Lokaliti Syarikat:",
    COMPANYLOC2: "Masukkan Lokaliti",
    COMPANYLOC3: "Tidak diwajibkan.",
    COMPANYCITY1: "Bandar:",
    COMPANYCITY2: "Masukkan Bandar",
    COMPANYCITY3: "Diperlukan.",
    POSCODE1: "Poskod:",
    POSCODE2: "Masukkan Poskod",
    POSCODE3: "Diperlukan.",
    STATE: "Negeri:",
  },
  ALERT: {
    SURE: "Anda Pasti?",
    WARNING: "Butiran Akan Dipadam!",
    SUCCESS: "Butiran Berjaya Disimpan",
    ALERT: "Diperlukan!",
    EMAIL: "Hantar emel pengesahan kepada ",
  },
  CLOCKPAGE: {
    HEADER: "Rekod Waktu Bekerja",
    RECORDS: "Tiada Rekod Kehadiran",
    RECORDS2:
      "Pekerja perlu daftar masuk terlebih dahulu untuk rekod kehadiran.",
    CLOSE: "Batal",
  },
  DEPARTMENTFORM: {
    HEADER: "Tambah Jabatan Baru",
    FORM: "Butiran Jabatan",
    NAME: "Nama Jabatan",
    DESCRIPTION: "Penerangan",
    DATECREATED: "Tarikh Penubuhan Jabatan",
    NAME1: "Masukkan Nama Jabatan",
    DESCRIPTION1: "Penerangan Tentang Jabatan",
  },
  DEVICEFORM: {
    HEADER: "Tambah Peranti Muka Baru",
    FORM: "Butiran Peranti Muka",
    SERIALNUM: "Nombor Siri",
    NAME1: "Masukkan Nama Peranti Muka",
    SERIALNUM1: "Masukkan Nombor Siri",
    DATEPURCHASED: "Tarikh Daftar Peranti Muka",
  },
  DEVICEEMPLOYEE: {
    HEADER: "Senarai Pekerja",
    IN: "In",
    LIST: "Senarai",
    STATUS: "Tiada Senarai Pekerja",
    STATUS1: "Piada Senarai Peranti",
    EMPLOYEECODE: "Kod Pekerja",
    SYNC: "Selarikan",
    SAVE: "Simpan",
  },
  PROFILE: {
    USERPROFILE: "Profil Pengguna",
    SIGNOUT: "Daftar Keluar",
    HI: "Selamat Datang, ",
    PROFILE: "Profil",
    ACCSETTINGS: "Tetapan Akaun",
    DEBUG: "Mod DEBUG",
  },
  SIDEBAR: {
    DASHBOARD: "Halaman Utama",
    COMPANY: "Syarikat",
    SETTINGS: "Tetapan",
    HOME: "Utama",
    EMPLOYEE: "Pekerja",
    DEPARTMENT: "Jabatan",
    ATTENDANCE: "Kehadiran",
    LEAVE: "Pelepasan Cuti",
    AWAY: "Ketiadaan",
    DEVICE: "Peranti",
    REGISTRATION: "Butiran",
    FACIAL: "Peranti Muka",
    MANAGEMENT: "Pengurusan",
    HOLIDAY: "Cuti",
    COMPREGISTRATION: "Daftar Syarikat",
  },
  LEAVE: {
    DETAILS: "Butiran",
    ENTITLE: "Kelayakan Cuti",
    AA: "Pengagihan Cuti",
    APPROVAL: "Pengesahanan Permohonan",
    STAT: "Statistik",
    LEAVEDETAILS: "Tambah Jenis Cuti",
    LEAVETYPE: "Butiran Cuti",
    TYPE: "Jenis Cuti",
    ENTITLEMENT: "Kelayakan Cuti",
    APPLICATION: "Pengesahan Permohonan",
    STATISTICS: "Statistik",
    LEAVEAPPLICATION: "Permohonan Cuti",
    EMPLEAVE: "Cuti Pekerja",
    APP: "Permohonan",
    APPLY: "Mohon Cuti",
    APPLYNEW: "Mohon {leave}",
    HOVER: {
      EDIT: "Sunting Cuti",
      DELETE: "Buang Cuti",
      NAVIGATEENT: "Navigasi ke Kehadiran Cuti",
      NAVIGATEASS: "Navigasi ke Pengagihan Cuti",
      EDITENT: "Sunting Kehadiran Cuti",
      ASSIGNLEAVE: "Agih Cuti",
    },
  },
  DETAILSTABLE: {
    TYPE: "JENIS CUTI",
    DESC: "PENERANGAN",
    UNIT: "UNIT CUTI",
    COUNT: "KIRAAN HARI",
    PAID: "CUTI BERBAYAR",
    ACT: "TINDAKAN",
  },
  DETAILSFORM: {
    TYPE: "Jenis Cuti",
    TYPE1: "Masukkan Jenis Cuti",
    DESC: "Penerangan",
    DESC1: "(200 karakter maksimum)",
    UNIT: "Unit Cuti",
    COUNT: "Kiraan Hari",
    PAID: "Cuti Berbayar?",
  },
  BUTTON: {
    CANCEL: "Batal",
    OKAY: "Ya",
    YES: "Ya",
    APPLY: "Mohon",
    UNVERIFIED: "Belum Disahkan",
    UPLOAD: "Muat Naik",
    PHOTO: "Gambar Profil",
    REJECT: "Tolak Permohonan",
  },
  LOGIN: {
    WELCOME: "Selamat Datang Ke SmartID Corporate",
    NEW: "Pengguna Baru?",
    REGISTER: "Daftar Akaun!",
    USERNAME: "Nama Pengguna",
    REQUIRED: "Nama pengguna diperlukan",
    PASSWORD: "Kata Laluan",
    REQUIRED2: "Kata Laluan diperlukan.",
    FORGOT: "Lupa Kata Laluan ?",
    LOGIN: "Daftar Masuk",
  },
  REGISTER: {
    HAVE: "Sudah Mempunyai Akaun?",
    ENTER: "Masukkan Butiran Untuk Mencipta Akaun",
    REQUIRED: "Nama Diperlukan",
    REQUIRED2: "Emel Yang Sah Diperlukan",
    REQUIRED3: "Emel Tempat Kerja Diperlukan",
    NUMBER: "Masukkan Nombor Sahaja",
    SIZE: "Saiz Syarikat",
    WORK: "Emel Syarikat",
    EMPLOYER: "Majikan",
  },
  NOTIFICATION: {
    USER: "Notifikasi Pengguna",
    MESSAGE: "Mesej",
    EVENTS: "Aktiviti",
  },
  PERSONAL: {
    PERSONAL: "Peribadi",
    ACCOUNT: "Akaun",
    PERSONALPROFILE: "Profil Peribadi",
    PERSONALDETAILS: "Butiran Peribadi",
    ACCOUNTDETAILS: "Butiran Akaun",
    EMERGENCYCONT: "Nombor Ketika Kecemasan",
    EDUDETAILS: "Butiran Pendidikan",
    GENERALDETAILS: "Butiran Am",
    PERSONALACC: "Akaun Peribadi",
  },
  FOOTER: {
    ABOUT: "Perihal",
    CONTACT: "Hubungi Kami",
  },
  JOB: {
    JOB: "Pekerjaan",
    POSITION: "Jawatan",
    POSITION1: "Masukkan Jawatan",
    EMPTYPE: "Status Pekerjaan",
    EMPTYPE1: "Masukkan Status Pekerjaan",
    EFFDATE: "Tarikh Berkuatkuasa",
    EFFDATE1: "Masukkan Tarikh Berkuatkuasa",
    EMPGROUP: "Kumpulan Pekerja",
    EMPGROUP1: "Masukkan Kumpulan Pekerja",
    PROBATION: "Tempoh Tamat Percubaan",
    REMARK: "Catatan",
    REMARK1: "Maksimum 2000 Perkataan",
    EMPSTATUS: "Status Pekerja",
    STATUS: "Status",
    STATUS1: "Masukkan Status Pekerja",
    JOBSTATUS: "Status Jawatan",
    POSITIONS: "Jawatan",
    CREATE: "Daftar Jawatan",
    CODE: "Kod",
  },
  SALARY: {
    SALARY: "Gaji",
    SALARY1: "Butiran Gaji",
    SALARY2: "Masukkan Gaji",
    SALARY3: "Gaji Pokok",
    SALARY4: "Masukkan Gaji Pokok",
    CURRENCY: "Matawang",
    CURRENCY1: "Masukkan Matawang",
    PAYMENT: "Butiran Pembayaran",
    PAYMENT1: "Pembayaran",
    PAYMENT2: "Masukkan Jenis Pembayaran",
    BANK: "Bank",
    BANK1: "Masukkan Nama Bank",
    ACCOUNT: "Akaun Bank",
    ACCOUNT1: "Masukkan Nombor Akaun Bank",
    METHOD: "Kaedah",
    METHOD1: "Masukkan Kaedah Bayaran",
    STATUTORY: "Butiran Statutori",
    REMARK: "Catatan",
    EPF: "Kadar KWSP Majikan",
    EPF1: "Masukkan Kadar KWSP Majikan",
    EPF2: "Nombor Keahlian KWSP",
    EPF3: "Masukkan Nombor Keahlian KWSP",
    EPF4: "Kadar KWSP Pekerja",
    EPF5: "Masukkan Kadar KWSP Pekerja",
    TAX: "Nombor Rujukan Cukai",
    TAX1: "Masukkan Nombor Rujukan Cukai",
    CHILD: "Pelepasan Anak",
    CHILD1: "Masukkan Pelepasan Anak",
    EIS: "Sumbangan EIS",
    EIS1: "Masukkan Sumbangan EIS",
    SOCSO: "Kategori KWSP",
    SOCSO1: "Masukkan Kategori KWSP",
    TAX2: "Status Zakat",
    TAX3: "Masukkan Status Cukai",
    ZAKAT: "Zakat",
    ZAKAT1: "Masukkan Jumlah Zakat",
  },
  FAMILY: {
    FAMILY: "Keluarga",
    MARITAL: "Status Perkahwinan",
    MARITAL1: "Masukkan Status Perkahwinan",
    NUMBER: "Bilangan Anak",
    NUMBER1: "Masukkan Bilangan Anak",
    SPOUSE: "Butiran Pasangan",
    NATIONALITY: "Kewarganegaraan",
    NATIONALITY1: "Masukkan Kewarganegaraan",
    NRIC: "Kad Pengenalan",
    NRIC1: "Masukkan Nombor Kad Pengenalan",
    PASSPORT: "Pasport",
    PASSPORT1: "Masukkan Pasport",
    CHILDREN: "Bilangan Anak",
    CHILDREN1: "Masukkan Bilangan Anak",
    CHILDREN2: "Butiran Anak",
    DETAILS: "Butiran Keluarga",
  },
  EDUCATION: {
    EDUCATION: "Pendidikan",
    EDUCATION1: "Latar Belakang Pendidikan",
    SCHOOL: "Institusi",
    SCHOOL1: "Masukkan Nama Institusi",
    DEGREE: "Ijazah",
    DEGREE1: "Masukkan Ijazah",
    FIELD: "Bidang Pengajian",
    FIELD1: "Masukkan Bidang Pengajian",
    GRADE: "Gred",
    GRADE1: "Masukkan Gred",
    FROM: "Dari (Tahun)",
    TO: "Ke (Tahun)",
    EDIT: "Sunting Pendidikan Butir",
    DELETE: "Buang Pendidikan Butir",
  },

  HOLIDAY: {
    HOLIDAY: "Cuti",
    HOLIDAY1: "Masukkan Cuti",
    DAY: "Hari",
    DATE: "Tarikh",
    CREATE: "Tambah Cuti",
  },

  ACCOUNT: {
    CHANGEEMAIL: "Tukar Alamat Emel",
    CHANGEEMAIL1: "Alamat Emel Baru",
    CHANGEEMAIL2: "Masukkan Alamat Emel Baru",
    CHANGEPASS: "Tukar Kata Laluan",
    ENTERPASS1: "Masukkan Kata Laluan",
    ENTERPASS2: "Masukkan Kata Laluan Baru",
    PASS1: "Kata Laluan",
    PASS2: "Sahkan Kata Laluan",
    PASS3: "Kata Laluan Baru",
    ALERT: "Untuk mengubah kata laluan, masukkan kata laluan baru dan klik ",
    ALERT2: "Untuk mengubah emel, masukkan emel baru dan klik ",
  },
  ENTITLEMENT: {
    CREATE: "Tambah Kelayakan Cuti",
    DETAILS: "Butiran Kelayakan Cuti",
    TYPE: "Jenis Cuti",
    DAYS: "Bilangan Hari Kelayakan Cuti",
    OVER: "Carried Over (in malay)",
    VALID: "Sah Sehingga",
    BALANCE: "Baki",
    REASON: "Alasan",
    PENDING: "Belum Diproses",
    BALANCE1: "Baki cuti",
    REASON1: "Masukkan Alasan",
    DAY: "Hari",
    SESSION: "Sesi",
    ATTACHMENT: "Lampiran",
    PURPOSE: "Untuk Kegunaan Pengurusan",
    APPLICATION: "Tarikh Permohonan",
    SESSION1: "Masukkan Sesi",
    APPROVE: "Sahkan",
    ATTACHMENT1: "Pilih Fail",
  },
  STATISTICS: {
    WEEK: "Minggu",
    PENDING: "Belum Diproses",
    APPROVED: "Disahkan",
    TOTAL: "Jumlah",
    WEEK1: "Minggu :",
    NEW: "Permohonan Baru",
    MONTH: "Bulan",
    YEAR: "Tahun",
  },

  BEACON: {
    BEACON: "Senarai Beacon",
    BEACON1: "Nama Beacon",
    BEACON2: "Masukkan Nama Beacon",
    HEADER: "Daftar Beacon",
  },

  DAILYATT: {
    IN: "Waktu Masuk",
    SEEN: "Kali Terakhir Dilihat",
    TEMP: "Suhu",
    STATUS: "Status",
  },

  ...EMPLOYEE,
  ...COMPANY,
  ...LEAVE,
  ...COMMON,
};
