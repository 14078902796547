import ApiService from "@/core/services/api.service";
import ImageUploadChunkService from "@/modules/core/services/file/image/image_upload_chunk_service";

const state = {
  employee: {},
};

const actions = {
  /**
   * Get the profile
   *
   * @param context
   */
  async getEmployeePersonalProfile(context) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get("/company/personal/profile");

      console.log("Employee Profile", response.data.data);
      context.commit("setEmployeePersonalProfile", response.data.data);

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   * Upload personal employee image from upload
   *
   * @param context
   * @param payload
   */
  async uploadPersonalImage(context, payload) {
    try {
      ApiService.setHeader();

      const chunks = ImageUploadChunkService.generateChunks(payload.image);
      console.log("Chunks", chunks);

      let counter = 0;
      let response;
      const fileName = require("crypto").randomBytes(64).toString("hex");

      for (const chunk of chunks) {
        let formData = new FormData();

        formData.set(
          "is_last",
          counter === chunks.length - 1 ? "true" : "false"
        );
        formData.set("image", chunk, `${fileName}.part`);

        response = await ApiService.post(
          "company/personal/profile/image",
          formData,
          ImageUploadChunkService.getConfig()
        );

        counter++;
      }

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};

const mutations = {
  setEmployeePersonalProfile(state, response) {
    state.employee = response;
  },
};

const getters = {
  getEmployeePersonalProfile: (state) => state.employee,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
