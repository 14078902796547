import ApiService from "@/core/services/api.service";

export default {
  submitEmployeeAwayIndividualAssignmentForm(context, payload) {
    ApiService.setHeader();
    return ApiService.post(
      "company/away/assignment/employee",
      payload.form
    ).then((res) => res);
  },
};
