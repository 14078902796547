export default [
  {
    text: "PERSONAL.PERSONAL",
    type: "menu-section",
    url: {},
    guard: {
      permissions: [],
    },
  },
  {
    text: "PROFILE.PROFILE",
    type: "menu-nav",
    url: {
      name: "personalProfile",
    },
    guard: {
      permissions: [],
    },
    icon: "/media/svg/icons/Communication/Adress-book2.svg",
  },
  {
    text: "PERSONAL.ACCOUNT",
    type: "menu-nav",
    url: {
      name: "accountInformation",
    },
    guard: {
      permissions: [],
    },
    icon: "/media/svg/icons/Clothes/Briefcase.svg",
  },
  {
    text: "SIDEBAR.ATTENDANCE",
    type: "menu-nav",
    url: {
      name: "personalAttendance",
    },
    guard: {
      permissions: [],
    },
    icon: "/media/svg/icons//Code/Time-schedule.svg",
  },
  {
    text: "SIDEBAR.LEAVE",
    type: "menu-nav",
    url: {
      name: "personalLeave",
    },
    icon: "/media/svg/icons/General/Clipboard.svg",
    guard: {
      permissions: [],
    },
  },
  {
    text: "SIDEBAR.AWAY",
    type: "menu-nav",
    url: {
      name: "personalAway",
    },
    icon: "/media/svg/icons/General/Clipboard.svg",
    guard: {
      permissions: [],
    },
  },
];
