import state from "@/modules/personal/stores/leave/application/list/state";

import ApiService from "@/core/services/api.service";

export default {
  /**
   * Fetch List.
   *
   * @param context
   */
  fetchPersonalLeaveApplicationList(context) {
    ApiService.setHeader();
    return ApiService.query("company/leave/personal/application", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
      },
    }).then((res) => {
      context.commit("setPersonalLeaveApplicationList", res.data.data);
      context.commit(
        "setPersonalLeaveApplicationPagination",
        res.data.meta.pagination
      );
    });
  },
};
