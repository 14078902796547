import state from "./state";
import mutations from "./mutations";
import actions from "./actions";

const getters = {
  getDailyLeaveAttendanceItem: (state) => state.leave,
  getDailyLeaveAttendanceIsBusy: (state) => state.isBusy,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
