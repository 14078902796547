const CompanyAwayDetailPage = () =>
  import("@/modules/company/components/away/detail/CompanyAwayDetailPage");

export default [
  {
    path: "company/away/detail",
    component: CompanyAwayDetailPage,
    name: "companyAwayDetailPage",
  },
];
