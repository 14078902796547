export default {
  Employee_Personal: {
    Profile: {
      Modal: {
        Title: "Personal Employee Photo",
      },
    },

    Tooltip: {
      Profile: "Upload Image",
    },
  },
};
