import ApiService from "@/core/services/api.service";
import ImageUploadChunkService from "@/modules/core/services/file/image/image_upload_chunk_service";

const state = {
  employeeId: null,
  relationships: [],
  states: [],
  imageUrl: "",
};

const actions = {
  assignEmployeeId(context, employeeId) {
    console.log("Employee Id", employeeId);
    context.commit("setEmployeeId", employeeId);
  },
  fetchContactRelations(context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("ref/employee/contact")
        .then((response) => {
          console.log(response);
          context.commit("setContactRelations", response);
          resolve(response);
        })
        .catch((reason) => {
          console.error(reason);
          reject(reason);
        });
    });
  },

  fetchContactDetailForm() {
    console.log("Fetching employee contact detail", state.employeeId);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(
        "employee/detail/contact",
        "?employee_id=" + state.employeeId
      )
        .then((response) => {
          console.log(response);
          // context.commit("setEmployeeContactDetailsForm", response);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  fetchPersonalDetailForm() {
    console.log("Fetching employee personal detail", state.employeeId);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(
        "employee/detail/personal",
        "?employee_id=" + state.employeeId
      )
        .then((response) => {
          console.log(response);
          // context.commit("setEmployeePersonalDetailsForm", response);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  fetchMartialStatus() {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("ref/marital")
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  submitEmployeeContactDetails(context, form) {
    form.employee_id = state.employeeId;
    console.log("Employee Id", state.employeeId);

    let url = "";
    if (form.id === null) url = "employee/detail/contact";
    else url = "employee/detail/contact/update";

    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(url, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Save employee personal details.
   *
   * @param context
   * @param form
   * @returns {Promise<unknown>}
   */
  async submitEmployeePersonalDetails(context, form) {
    try {
      form.employee_id = state.employeeId;
      console.log("Employee Id", state.employeeId);

      let url = "";
      if (form.id === null) url = "employee/detail/personal";
      else url = "employee/detail/personal/update";

      ApiService.setHeader();
      const response = await ApiService.post(url, form);

      return response.data;
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * Upload image with randomised name.
   *
   * @param context
   * @param image
   * @returns {*}
   */
  async uploadEmployeeImage(context, image) {
    try {
      ApiService.setHeader();
      const chunks = ImageUploadChunkService.generateChunks(image);

      let counter = 0;
      let response;
      const fileName = require("crypto").randomBytes(64).toString("hex");

      for (const chunk of chunks) {
        let formData = new FormData();
        formData.set(
          "is_last",
          counter === chunks.length - 1 ? "true" : "false"
        );
        formData.set("image", chunk, `${fileName}.part`);
        formData.append("employee_id", state.employeeId);

        response = await ApiService.post(
          "employee/profile/image",
          formData,
          ImageUploadChunkService.getConfig()
        );

        counter++;
      }

      context.commit("setUploadEmployeeImage", response.data.url);
      return response;
    } catch (e) {
      console.error(e);
    }
  },
};

const mutations = {
  setContactRelations(state, response) {
    console.log("The relationships", response.data.relation);
    state.relationships = response.data.relation;
    console.log(state.relationships);
  },
  setContactState(state, response) {
    console.log("The state", response.data.relation);
    state.states = response.data.relation;
    console.log(state.states);
  },
  setEmployeeId(state, employeeId) {
    state.employeeId = employeeId;
  },
  setUploadEmployeeImage(state, imageUrl) {
    state.imageUrl = imageUrl;
  },
};

const getters = {
  getUploadEmployeeImage: (state) => state.imageUrl,
  getSelectedEmployeeIdForDetails: (state) => state.employeeId,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
