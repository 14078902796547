const ManagementApplication = () =>
  import("@/modules/company/components/leave/application/LeavePageApplication");

export default [
  {
    path: "/company/leave/application",
    name: "managementApplication",
    component: ManagementApplication,
  },
];
