import ApiService from "@/core/services/api.service";

export default {
  /**
   * Submit employee personal leave application form.
   *
   * @param context
   * @param payload
   */
  async submitPersonalLeaveApplicationForm(context, payload) {
    try {
      ApiService.setHeader();

      context.commit("setPersonalLeaveEntitlementFormBusy", true);
      return await ApiService.post(
        "/company/personal/leave/application",
        payload.form
      );
    } catch (e) {
      console.error(e);
      console.log(e.response);

      throw e;
    } finally {
      context.commit("setPersonalLeaveEntitlementFormBusy", false);
    }
  },

  /**
   * Fetch personal leave entitlement form.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  async fetchPersonalLeaveEntitlementForm(context, payload) {
    try {
      ApiService.setHeader();

      const res = await ApiService.query(
        "company/personal/leave/entitlement/form",
        {
          params: {
            id: payload.id,
          },
        }
      );
      context.commit("setPersonalLeaveEntitlementForm", res.data.data);
      return res;
    } catch (e) {
      console.error(e);
      return e;
    }
  },
};
