import StatisticsRoute from "./statistics.router";
import AssignmentRoute from "./assignment.router";
import ApplicationRoute from "./application.router";

const ManagementDetails = () =>
  import("@/modules/company/components/leave/LeavePageDetails");
const ManagementEntitle = () =>
  import("@/modules/company/components/leave/entitlement/LeaveEntitlementPage");

const LeavePageEntitlementTable = () =>
  import(
    "@/modules/company/components/leave/entitlement/employee/LeaveEmployeeEntitlementPage"
  );

export default [
  {
    path: "/company/leave/details",
    name: "managementDetails",
    component: ManagementDetails,
  },
  {
    path: "/company/leave/entitlement/employee",
    name: "LeavePageEntitlementTable",
    component: LeavePageEntitlementTable,
  },

  {
    path: "/company/leave/entitlement",
    name: "managementEntitle",
    component: ManagementEntitle,
  },
  ...StatisticsRoute,
  ...AssignmentRoute,
  ...ApplicationRoute,
];
