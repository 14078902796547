export default {
  size: 15,
  items: [],
  isBusy: false,
  pagination: {},
  filers: {
    first_name: null,
    last_name: null,
    base: null,
  },
};
