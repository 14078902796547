const EmployeeAwayApplicationPage = () =>
  import(
    "@/modules/company/components/away/application/EmployeeAwayApplicationPage"
  );

export default [
  {
    path: "company/away/application",
    component: EmployeeAwayApplicationPage,
    name: "employeeAwayApplicationPage",
  },
];
