import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getIndividualEmployeeLeaveList: (state) => state.items,
  getIndividualEmployeeLeaveListPagination: (state) => state.pagination,
};

export default {
  actions,
  mutations,
  state,
  getters,
};
