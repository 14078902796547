const CalendarPage = () =>
  import("../../components/calendar/CompanyCalendarPage");

export default [
  {
    path: "company/calendar",
    name: "calendarList",
    component: CalendarPage,
  },
];
