import DEPARTMENT from "./department.en";
import DETAILS from "./details.en";
import FORM from "./form.en";
import GROUP from "./group.en";
import PERSONAL from "./personal/personal.en";

export default {
  EMPLOYEE: {
    HEADER: "Employees",
    SUBTITLE: "List of your company employees",
    BUTTON: "Create",
    EMP: "Employee Name",
    HOVER: {
      REFRESH: "Refresh",
      EDIT: "Edit Employee",
      ASSIGNADMIN: "Assign Admin",
      DELETE: "Delete Employee",
      MAIL: "Resend Verification Email",
      GROUPEDIT: "Edit Group",
      GROUPLIST: "Employee Group List",
      GROUPDELETE: "Delete Group",
    },
    GROUP: {
      NAME: "Group Name",
      BASE: "Group"
    }
  },

  EMPLOYEES: {
    DETAILS: {
      CONTACT: "Contact",
      GENERAL: "General",
      ADDRESS: "Address",
      RESET: "Reset",
      SUBMIT: "Submit",
    },
  },

  EMPLOYEETABLE: {
    FULLNAME: "Full Name",
    DEPARTMENTNAME: "Name",
    ACTIONS: "Actions",
    COUNT: "Emp. Count",
  },

  SUMMARY: {
    SUMMARY: "Summary",
    SUMMARY1: "Enter Employee Summary",
  },

  POSITIONS: {
    NAME: "Enter Position Name",
    CODE: "Enter Code",
    DETAILS: "Position Details",
  },

  ...DEPARTMENT,
  ...DETAILS,
  ...FORM,
  ...GROUP,
  ...PERSONAL,
};
