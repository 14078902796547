import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getEmployeeAdminList: (state) => state.items,
  getEmployeeAdminPagination: (state) => state.pagination,
  getEmployeeAdminListPage: (state) => state.pagination.current_page,
  getEmployeeAdminListBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
