import PersonalEmployeeLeaveManageApplication from "@/modules/personal/stores/leave/application/personal_leave_manage_application";
import Application from "./application/application.store";
import Entitlement from "./entitlement/personal_entitlement.store";

export default {
  modules: {
    PersonalEmployeeLeaveManageApplication,
    Application,
    Entitlement,
  },
};
