import List from "./list/list.module";
import Report from "./report/report.store";
import Leave from "./leave/leave.store";

export default {
  modules: {
    List,
    Report,
    Leave,
  },
};
