export default {
  /**
   * Set item.
   *
   * @param state
   * @param item
   */
  setDailyLeaveAttendanceItem(state, item) {
    console.log("Item: ", item);
    state.leave = item;
  },

  /**
   * Set is busy.
   *
   * @param state
   * @param item
   */
  setDailyLeaveAttendanceIsBusy(state, isBusy) {
    console.log("Holding State: ", isBusy);
    state.isBusy = isBusy;
  },
};
