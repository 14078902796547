import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   *
   * @param context
   * @returns {*}
   */
  fetchCompanyAwayDetailList(context) {
    ApiService.setHeader();
    return ApiService.query("company/away/management", {
      params: {
        "page[size]": 10,
        "page[number]": state.pagination.current_page ?? 1,
      },
    }).then((res) => {
      context.commit("setCompanyAwayDetailList", res.data.data);
      context.commit(
        "setCompanyAwayDetailListPagination",
        res.data.meta.pagination
      );
      return res;
    });
  },
};
