import List from "@/modules/company/stores/leave/assignment/company_leave_assignment_list";
import Employee from "@/modules/company/stores/leave/assignment/employee/employee_assignment.store";

const state = {};

const actions = {};

const mutations = {};

const getters = {};

export default {
  modules: {
    List,
    Employee,
  },
  state,
  actions,
  mutations,
  getters,
};
