import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";

export default {
  /**
   * Fetch monthly attendance by Date (Year/Month)
   *
   * @param context
   * @returns {*}
   */
  async fetchEmployeeMonthlyAttendanceList(context) {
    try {
      ApiService.setHeader();
      context.commit("setEmployeeMonthlyAttendanceBusy", true);

      const response = await ApiService.query("employee/attendance/monthly", {
        params: {
          "page[size]": state.size,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[base]": state.filter?.base,
          date: state.filter.date,
        },
      });

      context.commit("setEmployeeMonthlyAttendanceList", response.data);
      context.commit(
        "setEmployeeMonthlyAttendancePagination",
        response.data.meta.pagination
      );

      return response;
    } catch (error) {
      console.error(error);

      throw Error("Failed.");
    } finally {
      context.commit("setEmployeeMonthlyAttendanceBusy", false);
    }
  },

  async fetchEmployeeMonthlyRangeAttendanceList(context) {
    try {
      ApiService.setHeader();
      context.commit("setEmployeeMonthlyAttendanceBusy", true);

      const response = await ApiService.query(
        "employee/attendance/monthly/range",
        {
          params: {
            "page[size]": state.size,
            "page[number]": state.pagination.current_page ?? 1,
            "filter[base]": state.filter?.base,
            from_date: state.filter?.from_date,
            to_date: state.filter?.to_date,
          },
        }
      );

      console.log("Received ", response);
      context.commit("setEmployeeMonthlyAttendanceList", response.data);
      context.commit(
        "setEmployeeMonthlyAttendancePagination",
        response.data.meta.pagination
      );

      return response;
    } catch (error) {
      console.error(error);

      throw Error("Failed.");
    } finally {
      context.commit("setEmployeeMonthlyAttendanceBusy", false);
    }
  },

  /**
   * Download Monthly Report from month and year selection
   *
   * @returns {Promise<unknown>}
   */
  async downloadMonthlyReport(context, payload) {
    try {
      ApiService.setHeader();

      let dateType;
      if (state.date_type === 1) {
        dateType = {
          date: dayjs(state.filter.date).format(),
        };
      } else {
        dateType = {
          from_date: state.filter?.from_date,
          to_date: state.filter?.to_date,
        };
      }

      const response = await ApiService.query(
        "employee/attendance/report/monthly",
        {
          responseType: "blob",
          params: {
            type: payload.type ?? 1,
            date_type: state.date_type ?? 1,
            ...dateType,
          },
        }
      );

      AppReportDownloadService.processDownload(
        response.data,
        "Employee Monthly Report - Specific"
      );

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   * Set the filtered Year and Month
   *
   * @param context
   * @param payload is the Date (Year and Month)
   */
  defineEmployeeMonthlyAttendanceDateFilter(context, payload) {
    const date = payload.date;
    if (date !== null) {
      context.commit("setEmployeeMonthlyAttendanceDateFilter", date);
    }
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeEmployeeMonthlyAttendanceRangeFilter(context, payload) {
    const filters = state.filter;

    filters.from_date = payload.from_date;
    filters.to_date = payload.to_date;

    context.commit("setEmployeeMonthlyAttendanceFilters", filters);
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeEmployeeMonthlyAttendanceBaseFilter(context, payload) {
    const filters = state.filter;

    filters.base = payload.name;

    context.commit("setEmployeeMonthlyAttendanceFilters", filters);
  },

  /**
   * Change date type to either 1 or 2.
   *
   * @param context
   * @param payload
   */
  changeEmployeeMonthlyAttendanceDateType(context, payload) {
    console.log(payload);
    context.commit("setEmployeeMonthlyAttendanceDateType", payload.type);
  },
};
