import List from "./list/list.store";

const state = {
  items: [],
};

const actions = {};

const mutations = {};

const getters = {
  getCompanyLeaveApplication: (state) => state.items,
};

export default {
  modules: {
    List,
  },
  state,
  actions,
  mutations,
  getters,
};
