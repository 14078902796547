import ApiService from "@/core/services/api.service";
import dayjs from "dayjs";

export default {
  /**
   * Fetch.
   * @param context
   * @param payload
   */
  async fetchDailyLeaveAttendance(context, payload) {
    try {
      console.log("Fetching daily leave for attendance! ", payload);
      context.commit("setDailyLeaveAttendanceIsBusy", true);

      ApiService.setHeader();
      const res = await ApiService.query("company/leave/employee/daily", {
        params: {
          id: payload.id,
          date: dayjs().format(),
        },
      });

      context.commit("setDailyLeaveAttendanceItem", res.data.data.data);

      console.log("res", res);
      return res.data;
    } catch (e) {
      console.error(e);
    } finally {
      context.commit("setDailyLeaveAttendanceIsBusy", false);
    }
  },
};
