import ApiService from "@/core/services/api.service";

const state = {
  items: [],
  pagination: {},
  filters: {
    name: null,
  },
};

const actions = {
  /**
   * Fetch company leave list from server.
   */
  async fetchCompanyLeaveList(context) {
    try {
      ApiService.setHeader();
      const response = await ApiService.query("company/leave", {
        params: {
          "page[size]": 30,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[name]": state.filters?.name,
        },
      });

      context.commit("setCompanyLeaveList", response.data.data);
      context.commit(
        "setCompanyLeaveListPagination",
        response.data.meta.pagination
      );

      return response.data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   * Change base (All) filter.
   *
   * @param context
   * @param payload
   */
  changeCompanyLeaveListBaseFilter(context, payload) {
    console.log(payload);

    let filters = state.filters;
    filters.name = payload.base;

    context.commit("setCompanyLeaveListFilters", filters);
  },
};

const mutations = {
  /**
   * Set List
   *
   * @param state
   * @param data
   */
  setCompanyLeaveList(state, data) {
    state.items = data;
  },

  /**
   * Set pagination object
   *
   * @param state
   * @param pagination
   */
  setCompanyLeaveListPagination(state, pagination) {
    console.log("Pagination: ", state, pagination);
    state.pagination = pagination;
  },

  /**
   * Set the current page
   *
   * @param state
   * @param page
   */
  setCompanyLeaveListPage(state, page) {
    console.log("Pagination: ", state, page);
    state.pagination.current_page = page;
  },

  /**
   * Set current filters.
   *
   * @param state
   * @param filters
   */
  setCompanyLeaveListFilters(state, filters) {
    console.log(filters);

    state.filters = filters;
    console.log("Filters now: ", state.filters);
  },
};

const getters = {
  getCompanyLeaveList: (state) => state.items,
  getCompanyLeaveListPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
