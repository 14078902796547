import JOB from "./details/job.en";
import SALARY from "./details/salary.en";
import FAMILY from "./details/family.en";
import EDUCATION from "./details/education.en";

export default {
  ...JOB,
  ...SALARY,
  ...FAMILY,
  ...EDUCATION,
  EMPLOYEESGENERAL: {
    MESSAGE: {
      FIRSTNAME: "First Name",
      LASTNAME: "Last Name",
      EMAIL: "Email",
      UNIQUE: "Unique Code",
      EMPLOYEMENT: "Employment Date",
    },
  },
  EMPLOYEESCONTACT: {
    MESSAGE: {
      CONTACT: "Phone Number",
      EMERGENCY: "Name",
      RELATIONSHIP: "Relationship",
      ADDRESS1: "Address 1",
      ADDRESS2: "Address 2",
      ADDRESS3: "Address 3",
      LOCALITY: "Locality",
      CITY: "City Town",
      POSTALCODE: "Postal Code",
      STATE: "State",
    },
  },
  EMPLOYEESPERSONAL: {
    MESSAGE: {
      DOB: "Birth Date",
      DOB1: "Enter Birth Date",
      GENDER: "Gender",
      MARITALID: "Marital Status",
      ADDRESS1: "Address 1",
      ADDRESS2: "Address 2",
      ADDRESS3: "Address 3",
      LOCALITY: "Locality",
      CITY: "City Town",
      POSTALCODE: "Postal Code",
      STATE: "State",
      PHONENUM: "Phone Number",
      IDENTITYTYPE: "Identity Type",
      IDENTITYNUM: "Identity Number",
    },
  },
};
