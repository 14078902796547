import ApiService from "@/core/services/api.service";
import state from "@/modules/company/stores/leave/application/list/state";

export default {
  /**
   * Fetch All Company leave Application
   *
   * @param context
   */
  async fetchAllCompanyLeaveApplication(context) {
    try {
      context.commit("setAllCompanyLeaveApplicationListBusy", true);
      ApiService.setHeader();
      const response = await ApiService.query("company/leave/application", {
        params: {
          "page[number]": state.pagination.current_page,
          "page[size]": state.size,
          "filter[base]": state.filters?.base,
          // "filter[type]": state.filters?.base,
        },
      });

      context.commit("setAllCompanyLeaveApplicationList", response.data.data);
      context.commit(
        "setAllCompanyLeaveApplicationPagination",
        response.data.meta.pagination
      );

      return response.data.data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      context.commit("setAllCompanyLeaveApplicationListBusy", false);
    }
  },

  /**
   * Change base filter.
   *
   * @param context
   * @param payload
   */
  changeAllCompanyLeaveApplicationBaseFilter(context, payload) {
    console.log(payload);

    const filters = state.filters;
    filters.base = payload.base;

    context.commit("setAllCompanyLeaveApplicationFilters", filters);
  },
};
