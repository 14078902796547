import ApiService from "@/core/services/api.service";
import Vue from "vue";

const state = {
  list: [],
  meta: {},
  pagination: {
    current_page: 1,
  },
  currentPage: 2,
  isBusy: false,
  isSuccess: true,
  group: {},
};

function extracted() {
  console.log("List", state.list);

  let employees = [];
  state.list.forEach((em) => {
    console.log(em);
    if (em.selected) {
      employees.push(em.id);
    }
  });
  console.log(employees);
  return employees;
}

const actions = {
  /**
   * Fetch the current group members.
   * @param context
   * @returns {Promise<AxiosResponse<*>>}
   */
  async fetchGroupMembers(context) {
    ApiService.setHeader();
    state.isBusy = true;

    try {
      const response = await ApiService.query("group/employees", {
        params: {
          "page[number]": state.meta.currentPage ?? 1,
          group_id: state.group.id,
        },
      });

      context.commit("commitGroupMembers", response);
      return response;
    } catch (e) {
      console.log(e);
      throw Error("Failed");
    } finally {
      state.isBusy = false;
    }
  },

  /**
   * Get the available employees not yet saved in a group.
   * @param context
   * @returns {Promise<boolean>}
   */
  fetchAvailableGroupMembers(context) {
    ApiService.setHeader();
    state.isBusy = true;
    console.log("current page", state.pagination.current_page);
    return Vue.axios
      .get("group/employees/not", {
        params: {
          "page[number]": state.pagination.current_page ?? 1,
          group_id: state.group.id,
        },
      })
      .then((res) => {
        context.commit("commitAvailableGroupMembers", res);
      })
      .finally(() => (state.isBusy = false));
  },

  /**
   * Save the selected group members.
   * @returns {*}
   */
  saveEmployeeGroupMembers(context) {
    const employees = extracted();
    ApiService.setHeader();

    return ApiService.post("group/employees", {
      employees,
      group_id: state.group.id,
    }).then(() => context.commit("setGroupMembershipSuccess"));
  },

  /**
   * Delete the selected employee members
   * @returns {*}
   */
  deleteEmployeeGroupMembers(context) {
    const employees = extracted();

    ApiService.setHeader();
    return ApiService.delete("group/employees", {
      params: {
        employees: employees,
        group_id: state.group.id,
      },
    }).then(() => context.commit("setGroupMembershipSuccess"));
  },

  /**
   * Set employee item to be selected (delete, add).
   *
   * @param context
   * @param payload
   */
  selectEmployeeGroupMemberModification(context, payload) {
    console.log("Info : ", context, payload);

    context.commit("switchGroupMemberSelection", payload.id);
  },
};

const mutations = {
  /**
   * Commit.
   *
   * @param state
   * @param res
   */
  commitGroupMembers(state, res) {
    const members = res.data.data;
    console.log("Commit", members);
    state.list = members;
  },

  /**
   * Commit available.
   *
   * @param state
   * @param res
   */
  commitAvailableGroupMembers(state, res) {
    const members = res.data.data;
    console.log("Commit", members);
    state.list = members;
    state.pagination = res.data.meta.pagination;
    console.log(state.pagination);
  },

  /**
   * Set group.
   *
   * @param state
   * @param group
   */
  setGroup(state, group) {
    state.group = group;
    console.log(state.group);
  },

  /**
   * Set membership success.
   *
   * @param state
   */
  setGroupMembershipSuccess(state) {
    state.isSuccess = true;
  },

  /**
   * Flip switch.
   *
   * @param state
   * @param employeeId
   */
  switchGroupMemberSelection(state, employeeId) {
    console.log("Info: ", state, employeeId);

    const index = state.list.findIndex((e) => e.id === employeeId);
    console.log("Matched row: ", state.list[index]);

    state.list[index].selected = !state.list[index].selected;
    console.log("Changed row: ", state.list[index]);
  },
};

const getters = {
  getMembershipListBusy: (state) => {
    console.log("BUSY TAK?", state.isBusy);
    return state.isBusy;
  },
  getMembershipList: (state) => state.list,
  getMembershipPagination: (state) => state.pagination,
  getMembershipEmpty: (state) => {
    return state.list.length <= 0;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
