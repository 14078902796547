import ApiService from "@/core/services/api.service";

const state = {
  form: {
    name: "",
    description: "",
    time_in: "",
    cut_off_time_in: "",
    time_out: "",
    cut_off_time_out: "",
    disable_late: "0",
  },
  isBusy: false,
};

const actions = {
  /**
   * Save employee group.
   *
   * @returns {Promise<*>}
   */
  async saveEmployeeGroup() {
    console.log("Submitting,", state.form);
    state.isBusy = true;
    ApiService.setHeader();

    const formBody = {
      name: state.form.name,
      description: state.form.description,
      allow_late: state.form.disable_late === "0" ? 1 : 0,
    };

    return ApiService.post("group/record/register", formBody)
      .then((response) => {
        console.log("Registered device", response);
      })
      .finally(() => {
        state.isBusy = false;
      });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
};
