export default {
  /**
   * Set individual staff away list items.
   *
   * @param state
   * @param items
   */
  setIndividualEmployeeAwayList(state, items) {
    console.log("Items : ", items);

    state.items = items;
  },

  /**
   * Set individual staff filters.
   *
   * @param state
   * @param filters
   */
  setIndividualEmployeeAwayListFilters(state, filters) {
    console.log("Filters", filters);

    state.filters = filters;
  },

  /**
   * Set individual staff away list pagination.
   *
   * @param state
   * @param pagination
   */
  setIndividualEmployeeAwayListPagination(state, pagination) {
    console.log("Items : ", pagination);

    state.pagination = pagination;
  },

  /**
   * Set current page.
   *
   * @param state
   * @param page
   */
  setIndividualEmployeeAwayListPage(state, page) {
    console.log("Page : ", page);

    state.pagination.current_page = page;
  },
};
