import List from "@/modules/company/stores/leave/assignment/employee/employee_assignment_list";
import Form from "@/modules/company/stores/leave/assignment/employee/employee_assignment_form";

const state = {
  employee: {},
  isBusy: false,
};

const actions = {
  assignSelectedAssignmentForEmployee(context, employee) {
    context.commit("setSelectedAssignmentForEmployee", employee);
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param employee
   */
  setSelectedAssignmentForEmployee(state, employee) {
    state.employee = employee;
  },
};

const getters = {
  getSelectedAssignForEmployee: (state) => state.employee,
};

export default {
  modules: {
    List,
    Form,
  },
  state,
  actions,
  mutations,
  getters,
};
