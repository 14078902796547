import ApiService from "@/core/services/api.service";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";

const state = {
  meta: {},
  groups: [],
  response: {},
  isBusy: false,
  filters: {
    name: null,
    count: null,
  },
};

const actions = {
  /**
   * @param context
   */
  async getEmployeeGroup(context) {
    try {
      state.isBusy = true;
      context.commit("clearEmployeeGroup");
      ApiService.setHeader();

      console.log("State :", state);

      const response = await ApiService.query("/group/record", {
        params: {
          "page[number]": state.meta.current_page ?? 1,
          "page[size]": 20,
          "filter[name]": state.filters?.name,
          "filter[count]": state.filters?.count,
        },
      });

      context.commit("setEmployeeGroup", response);
      context.commit(
        "setEmployeeGroupPagination",
        response.data.meta.pagination
      );
    } catch (error) {
      console.error(error);
    } finally {
      state.isBusy = false;
    }
  },

  /**
   * Download employee group report.
   *
   * @param context
   * @param payload
   * @returns {Promise<AxiosResponse<*>>}
   */
  async downloadEmployeeGroupReport(context, payload) {
    try {
      ApiService.setHeader();

      const response = await ApiService.query("group/record/report", {
        responseType: "blob",
        params: {
          "filter[name]": state.filters?.name,
          "filter[count]": state.filters?.count,
          type_id: payload?.type_id ?? 1,
        },
      });

      console.log(response);
      AppReportDownloadService.processDownload(
        response.data,
        " Group Report - "
      );

      return response;
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Change filters
   *
   * @param context
   * @param payload
   */
  changeEmployeeGroupListFilters(context, payload) {
    console.log(payload);

    context.commit("setEmployeeGroupListFilters", payload.filters);
  },

  /**
   * Change name filter.
   *
   * @param context
   * @param payload
   */
  changeEmployeeGroupListNameFilter(context, payload) {
    const filters = state.filters;
    filters.name = payload.name;

    context.commit("setEmployeeGroupListFilters", filters);
  },

  /**
   * Change count query.
   *
   * @param context
   * @param payload
   */
  changeEmployeeGroupListCountFilter(context, payload) {
    const filters = state.filters;
    filters.count = payload.count;

    context.commit("setEmployeeGroupListFilters", filters);
  },

  /**
   * Delete employee group using GROUP ID.
   *
   * @param context
   * @param payload
   */
  deleteEmployeeGroup(context, payload) {
    ApiService.setHeader();

    const id = payload.id;
    console.log("ID", id);
    return ApiService.delete("group/record", {
      params: {
        id: id,
      },
    });
  },
};

const mutations = {
  /**
   * Set emp. group.
   *
   * @param state
   * @param response
   */
  setEmployeeGroup(state, response) {
    state.response = response;
    state.groups = response.data.data;
  },

  /**
   * Set group pagination.
   *
   * @param state
   * @param pagination
   */
  setEmployeeGroupPagination(state, pagination) {
    state.meta = pagination;
  },

  /**
   * Set page
   *
   * @param state
   * @param page
   */
  setEmployeeGroupPage(state, page) {
    console.log("Page: ", page);
    state.meta.current_page = page;
  },

  /**
   * Clear the group.
   *
   * @param state
   */
  clearEmployeeGroup(state) {
    state.groups = [];
  },

  /**
   * Set employee group filters.
   *
   * @param state
   * @param filters
   */
  setEmployeeGroupListFilters(state, filters) {
    console.log("FIlters: ", filters);

    state.filters = filters;
  },
};

const getters = {
  getEmployeeGroup: (state) => state.groups,
  getEmployeeGroupPagination: (state) => state.meta,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
