import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   * Fetch individual staff away list.
   *
   * @param context
   * @returns {*}
   */
  fetchIndividualEmployeeAwayMonthlyList(context) {
    ApiService.setHeader();

    return ApiService.query(
      "company/away/application/employee/individual/monthly",
      {
        params: {
          "page[number]": state.pagination.current_page ?? 1,
          "page[size]": state.size,
          date: state.filters.date,
          id: state.filters.employeeId,
        },
      }
    ).then((res) => {
      console.log(res);
      context.commit("setIndividualEmployeeAwayList", res.data.data);
      context.commit(
        "setIndividualStaffEmployeeListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Fetch individual staff away list.
   *
   * @param context
   * @returns {*}
   */
  fetchIndividualEmployeeAwayYearlyList(context) {
    ApiService.setHeader();

    return ApiService.query(
      "company/away/application/employee/individual/yearly",
      {
        params: {
          "page[number]": state.pagination.current_page ?? 1,
          "page[size]": state.size,
          date: state.filters.date,
          id: state.filters.employeeId,
        },
      }
    ).then((res) => {
      console.log(res);
      context.commit("setIndividualEmployeeAwayList", res.data.data);
      context.commit(
        "setIndividualEmployeeAwayListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Change the filter.
   *
   * @param context
   * @param payload
   */
  changeIndividualEmployeeAwayListFilter(context, payload) {
    console.log("Payload", payload);
    const filters = {
      date: payload.date ?? dayjs().format(),
      employeeId: payload.employeeId ?? null,
    };

    context.commit("setIndividualEmployeeAwayListFilters", filters);
  },
};
