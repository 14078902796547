export default {
  /**
   * Set the List items
   *
   * @param state
   * @param items
   */
  setCompanyAwayDetailList(state, items) {
    state.items = items;
  },

  /**
   * Set List pagination meta
   *
   * @param state
   * @param pagination
   */
  setCompanyAwayDetailListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set List current page
   *
   * @param state
   * @param currentPage
   */
  setCompanyAwayDetailListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },
};
