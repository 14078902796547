import Vue from "vue";
import Vuex from "vuex";

import Auth from "@/modules/auth/store/auth";
import htmlClass from "./htmlclass.module";
import SiteConfig from "./config.module";
import BreadCrumbs from "./breadcrumbs.module";
import AuthModule from "@/modules/auth/store/auth.module";
import CoreModule from "@/modules/core/store/core.module";
import CompanyRegistration from "@/modules/company/stores/registration/company_registration";
import CompanyModule from "@/modules/company/stores/company.module";
import DeviceModule from "@/modules/device/store/device.module";
import SettingsModule from "@/modules/setting/stores/settings.module";
import PersonalModule from "@/modules/personal/stores/personal.module";
import DashboardModule from "../../../modules/dashboard/store/dashboard.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    htmlClass,
    SiteConfig,
    BreadCrumbs,
    AuthModule,
    CoreModule,
    CompanyModule,
    CompanyRegistration,
    DeviceModule,
    SettingsModule,
    PersonalModule,
    DashboardModule,
  },
});
