export default {
  /**
   * Set List
   *
   * @param state
   * @param data
   */
  setPersonalLeaveApplicationList(state, data) {
    state.items = data;
  },

  /**
   * Set List pagination
   *
   * @param state
   * @param pagination
   */
  setPersonalLeaveApplicationPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set List pagination current page
   *
   * @param state
   * @param currentPage
   */
  setPersonalLeaveApplicationPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },
};
