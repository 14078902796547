import dayjs from "dayjs";
import ApiService from "@/core/services/api.service";
import SchoolReportDownloadService from "@/modules/core/services/file/file_download.service";

export default {
  /**
   * Download staff daily attendance excel report
   *
   * @param context
   * @param payload
   */
  downloadStaffDailyAttendanceReport(context, payload) {
    const date = payload.date ?? dayjs().format();

    ApiService.setHeader();
    return ApiService.query("school/attendance/staff/report", {
      responseType: "blob",
      params: {
        date: date,
        type: payload.type,
      },
    }).then((response) => {
      console.log(response);
      SchoolReportDownloadService.processDownload(
        response.data,
        "Staff Daily Attendance"
      );
    });
  },

  /**
   * Download Staff Monthly Attendance Report
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  downloadStaffMonthlyAttendanceReport(context, payload) {
    const date = payload.date ?? dayjs().format();

    ApiService.setHeader();
    return ApiService.query("school/attendance/staff/monthly/report", {
      responseType: "blob",
      params: {
        date: date,
        type: payload.type,
      },
    }).then((response) => {
      console.log(response);
      SchoolReportDownloadService.processDownload(
        response.data,
        "Staff Monthly Attendance - "
      );
    });
  },
};
