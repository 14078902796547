const LeavePageStatisticsList = () =>
  import(
    "@/modules/company/components/leave/statistics/LeavePageStatisticsList"
  );
const ManagementStatistics = () =>
  import("@/modules/company/components/leave/statistics/LeavePageStatistics");

export default [
  {
    path: "/company/leave/statistics",
    name: "managementStatistics",
    component: ManagementStatistics,
  },
  {
    path: "/company/components/leave/entitlement/employee",
    name: "LeavePageStatisticsList",
    component: LeavePageStatisticsList,
  },
];
