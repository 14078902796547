import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   * Fetch individual entitlement list
   *
   * @param context
   * @param payload
   */
  fetchEmployeeAwayIndividualEntitlementList(context, payload) {
    const id = payload.id;
    console.log("Employee id", id);

    ApiService.setHeader();
    return ApiService.query("company/away/entitlement/employee", {
      params: {
        id: id,
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
      },
    }).then((res) => {
      context.commit("setEmployeeAwayIndividualEntitlementList", res.data.data);
      context.commit(
        "setEmployeeAwayIndividualEntitlementListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Get the individual teacher entitlement form.
   *
   * @param context
   * @param payload
   */
  fetchEmployeeAwayIndividualEntitlementForm(context, payload) {
    ApiService.setHeader();
    return ApiService.query("company/away/entitlement/employee/form", {
      params: {
        id: payload.id,
      },
    }).then((res) => {
      context.commit("setEmployeeAwayIndividualEntitlementForm", res.data);
    });
  },
};
