import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getEmployeeMonthlyAttendanceExportList: (state) => state.items,
  getEmployeeMonthlyAttendanceExportListPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
