export default {
  /**
   * @param state
   * @param items
   */
  setEmployeeYearlyAttendanceList(state, items) {
    console.log("Items", items);

    state.items = items;
    console.log("Current State ", state);
  },

  /**
   * @param state
   * @param pagination
   */
  setEmployeeYearlyAttendancePagination(state, pagination) {
    console.log("Pagination", pagination);

    state.pagination = pagination;
    console.log("Current State ", state);
  },

  /**
   * Set the current page
   *
   * @param state
   * @param currentPage
   */
  setEmployeeYearlyAttendancePage(state, currentPage) {
    console.log("Page to change to", currentPage);

    state.pagination.current_page = currentPage;
  },

  /**
   * Change date filter
   *
   * @param state
   * @param currentDate
   */
  setEmployeeYearlyAttendanceDate(state, currentDate) {
    console.log("Current Date", currentDate);
    state.filter.date = currentDate;
  },

  /**
   *
   * @param state
   * @param filters
   */
  setEmployeeYearlyAttendanceFilters(state, filters) {
    console.log(filters);
    state.filter = filters;
  },

  /**
   * Set monthly busy
   *
   * @param state
   * @param isBusy
   */
  setEmployeeYearlyAttendanceBusy(state, isBusy) {
    console.log(isBusy);
    state.isBusy = isBusy;
  },
};
