export default {
  EMPLOYEEFORM: {
    EMPLOYEEDETAILS: "Employee Details",
    NAME: "Enter First Name",
    LASTNAME: "Enter Last Name",
    EMAIL: "Enter Email",
    UNIQUECODE: "Enter Unique Code",
    STARTDATE: "Enter Employment Date",
    ADD: "Enter Address",
    LOCALITY: "Enter Locality",
    TOWN: "Enter Town",
    POSTALCODE: "Enter Postal Code",
    EMERGENCYNUMBER: "Enter Emergency Contact Number",
    EMERGENCYCONTACT: "Enter Emergency Contact Name",
    PERSONAL: "Personal",
    CREATE: "Create Employee",
    PHONENUM: "Enter Phone Number",
    IDENTITYTYPE: "Please Choose Identity Type",
    IDENTITYNUM: "Enter Identity Number",
  },
};
