import DEPARTMENT from "./department.ms";
import DETAILS from "./details.ms";
import FORM from "./form.ms";
import GROUP from "./group.ms";
import PERSONAL from "./personal/personal.ms";

export default {
  EMPLOYEE: {
    HEADER: "Pekerja",
    SUBTITLE: "Senarai Pekerja Syarikat Anda",
    BUTTON: "Tambah",
    EMP: "Nama Pekerja",
    HOVER: {
      REFRESH: "Segar",
      EDIT: "Sunting Pekerja",
      ASSIGNADMIN: "Menugaskan Admin",
      DELETE: "Buang Pekerja",
      MAIL: "Hantar Semula Verifikasi Email",
      GROUPEDIT: "Sunting Kumpulan",
      GROUPLIST: "Senarai Kumpulan Pekerja",
      GROUPDELETE: "Buang Kumpulan",
    },
    GROUP: {
      NAME: "Nama Kumpulan",
      BASE: "Kumpulan",
    },
  },

  EMPLOYEES: {
    DETAILS: {
      CONTACT: "Kenalan",
      GENERAL: "Umum",
      ADDRESS: "Alamat",
      RESET: "Tetap Semula",
      SUBMIT: "Simpan",
    },
  },

  EMPLOYEETABLE: {
    FULLNAME: "Nama",
    DEPARTMENTNAME: "Nama Jabatan",
    ACTIONS: "Tindakan",
    COUNT: "Bilangan Pekerja",
  },

  SUMMARY: {
    SUMMARY: "Catatan",
    SUMMARY1: "Masukkan Catatan Pekerja",
  },

  POSITIONS: {
    NAME: "Masukkan Nama Jawatan",
    CODE: "Masukkan Kod",
    DETAILS: "Penerangan Jawatan",
  },

  ...DEPARTMENT,
  ...DETAILS,
  ...FORM,
  ...GROUP,
  ...PERSONAL,
};
