const DailyAttendance = () =>
  import(
    "@/modules/company/components/attendance/daily/EmployeeDailyAttendance"
  );

const MonthlyAttendance = () =>
  import(
    "@/modules/company/components/attendance/monthly/EmployeeMonthlyAttendance"
  );

const YearlyAttendance = () =>
  import(
    "@/modules/company/components/attendance/yearly/EmployeeYearlyAttendance"
  );

export default [
  { path: "daily", component: DailyAttendance, name: "employeeAttendance" },
  {
    path: "monthly",
    component: MonthlyAttendance,
    name: "employeeAttendanceMonthly",
  },
  {
    path: "yearly",
    component: YearlyAttendance,
    name: "employeeAttendanceYearly",
  },
];
