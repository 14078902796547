import Vue from "vue";
import VueGapi from "vue-gapi";

Vue.use(VueGapi, {
  apiKey: "AIzaSyCKPBZz2kWrw1_YSNJYGJx14etX2yo8VA4",
  clientId:
    "387219034040-qegq9impvof1q2qfit5ah47c2tgbgrm2.apps.googleusercontent.com",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
  scope: "https://www.googleapis.com/auth/calendar",
});
