import ApiService from "@/core/services/api.service";
import state from "./state";

export default {
  /**
   * Fetch Employee Yearly Attendance
   *
   * @param context
   * @param payload
   */
  fetchIndividualEmployeeYearlyAttendanceList(context, payload) {
    ApiService.setHeader();
    return ApiService.query("employee/attendance/yearly/individual", {
      params: {
        id: payload.id,
        date: state.filter.date,
      },
    }).then((res) => {
      console.log("Received ", res);
      context.commit(
        "setIndividualEmployeeYearlyAttendanceList",
        res.data.data
      );

      return res;
    });
  },

  /**
   * Change list filter date
   *
   * @param context
   * @param payload
   */
  changeEmployeeYearlyAttendanceListDate(context, payload) {
    const date = payload.date;
    if (date !== null) {
      context.commit("setIndividualEmployeeYearlyAttendanceDate", date);
    }
  },
};
