import ApiService from "@/core/services/api.service";

const actions = {
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  deleteCompanyAwayDetail(context, payload) {
    ApiService.setHeader();
    return ApiService.delete("company/away/management", {
      params: {
        id: payload,
      },
    }).then((response) => response);
  },
};

export default {
  actions,
};
