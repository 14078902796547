import state from "@/modules/company/stores/leave/application/list/state";
import actions from "@/modules/company/stores/leave/application/list/actions";
import mutations from "@/modules/company/stores/leave/application/list/mutations";

const getters = {
  getAllCompanyLeaveApplicationPagination: (state) => state.pagination,
  getAllCompanyLeaveApplicationList: (state) => state.items,
  getAllCompanyLeaveApplicationBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
