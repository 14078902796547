import EMPLOYEE from "./en/employees/employees.en";
import COMPANY from "./en/company/company.en";
import LEAVE from "./en/attendance/leave/leave.en";
import COMMON from "./en/common/common.en";

// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },

  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      PENDING: "Pending",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
  EMPLOYEE: {
    HEADER: "Employees",
    SUBTITLE: "List of your company employees",
    BUTTON: "Create",
    EMP: "Employee Name",
    HOVER: {
      REFRESH: "Refresh",
      EDIT: "Edit Employee",
      ASSIGNADMIN: "Assign Admin",
      DELETE: "Delete Employee",
      MAIL: "Resend Verification Email",
      GROUPEDIT: "Edit Group",
      GROUPLIST: "Employee Group List",
      GROUPDELETE: "Delete Group",
    },
  },
  DEVICE: {
    FACIAL: {
      PAGE: {
        HEADER: "Facial Devices",
        HEADERFORM: "Create Facial Devices",
        SUBTITLE: "Facial Devices List",
        BUTTON: "Register",
        DEVICE: "Device",
        MODEL: "Model",
        SERIALNUMBER: "Serial Number",
        ACTIONS: "Actions",
        BEACON: "Beacon",
        DEVICENAME: "Device Name",
        DEVICENAME1: "Enter Device Name",
      },
    },
  },
  DEPARTMENT: {
    HEADER: "Department",
    SUBTITLE: "List of departments",
    BUTTON: "Create",
    DAILY: "Daily",
    MONTHLY: "Monthly",
  },
  ATTENDANCE: {
    IN: "In",
    OUT: "Last Seen",
    ACTIONS: "Actions",
    HEADER: "Employee Attendances",
    SUBTITLE: "Your company employee attendance record",
    BUTTON: "Refresh",
    TEMPERATURE: "Temp.",
    TODAY: "Today",
    PUNCHIN: "Punch In",
    PUNCHOUT: "Punch Out",
    DAILY: "Daily",
    MONTHLY: "Monthly",
    MONTH: "Your Monthly Attendance",
    DATE: "Date",
    HOVER: {
      CLOCK: "Clock Record",
      MODIFY: "Modify Attendance",
      TIME: "Update Time Attendance",
      MONTHLY: "Monthly Attendance Detail",
      YEARLY: "Yearly Attendance Detail",
    },
  },
  COMPANY: {
    REGISTRATION: {
      GENERAL: "General",
      CONTACT: "Contacts",
      RESET: "Reset",
      SUBMIT: "Submit",
    },
  },
  EMPLOYEES: {
    DETAILS: {
      CONTACT: "Contact",
      GENERAL: "General",
      ADDRESS: "Address",
      RESET: "Reset",
      SUBMIT: "Submit",
    },
  },
  EMPLOYEESGENERAL: {
    MESSAGE: {
      FIRSTNAME: "First Name",
      LASTNAME: "Last Name",
      EMAIL: "Email",
      UNIQUE: "Unique Code",
      EMPLOYEMENT: "Employment Date",
    },
  },
  EMPLOYEESCONTACT: {
    MESSAGE: {
      CONTACT: "Emergency Contact Number",
      EMERGENCY: "Emergency Name",
      RELATIONSHIP: "Relationship",
      ADDRESS1: "Address 1",
      ADDRESS2: "Address 2",
      ADDRESS3: "Address 3",
      LOCALITY: "Locality",
      CITY: "City Town",
      POSTALCODE: "Postal Code",
      STATE: "State",
    },
  },
  EMPLOYEESPERSONAL: {
    MESSAGE: {
      DOB: "Birth Date",
      DOB1: "Enter Birth Date",
      GENDER: "Gender",
      MARITALID: "Marital Status",
      ADDRESS1: "Address 1",
      ADDRESS2: "Address 2",
      ADDRESS3: "Address 3",
      LOCALITY: "Locality",
      CITY: "City Town",
      POSTALCODE: "Postal Code",
      STATE: "State",
      PHONENUM: "Phone Number",
      IDENTITYTYPE: "Identity Type",
      IDENTITYNUM: "Identity Number",
    },
  },
  EMPLOYEETABLE: {
    FULLNAME: "Full Name",
    DEPARTMENTNAME: "Name",
    ACTIONS: "Actions",
  },
  COMPANYFORM: {
    COMPANYFORM1: "Company Profile Form",
    COMPANYFULLNAME1: "Company Full Name:",
    COMPANYFULLNAME2: "Enter Company Full Name",
    COMPANYFULLNAME3: "Company Full Name Based on Registration Form.",
    COMPANYTYPE: "Company Type:",
    COMPANYREGNUM: "Company Registration Number:",
    COMPANYREGNUM1: "Enter Registration Number",
    COMPANYNAME: "Company Name:",
    COMPANYNAME1: "Enter Name",
    COMPANYDESC: "Company Description:",
    COMPANYDESC1: "Enter Description",
    COMPANYEMAIL: "Company Email:",
    COMPANYEMAIL1: "Enter Email",

    COMPANYPHONE: "Company Phone Number:",
    COMPANYPHONE1: "Enter Phone Number:",
    COMPANYADDRESS1: "Company Address 1:",
    COMPANYADDRESS12: "Enter Address",
    COMPANYADDRESS13: "Required.",
    COMPANYADDRESS2: "Company Address 2:",
    COMPANYADDRESS22: "Enter Address",
    COMPANYADDRESS23: "Optional.",
    COMPANYADDRESS3: "Company Address 3:",
    COMPANYADDRESS32: "Enter Address",
    COMPANYADDRESS33: "Optional.",
    COMPANYLOC1: "Company Locality:",
    COMPANYLOC2: "Enter Locality",
    COMPANYLOC3: "Optional.",
    COMPANYCITY1: "Company City:",
    COMPANYCITY2: "Enter City",
    COMPANYCITY3: "Required.",
    POSCODE1: "Company Postal Code:",
    POSCODE2: "Enter Postal Code",
    POSCODE3: "Required.",
    STATE: "Company State:",
  },
  ALERT: {
    SURE: "Are You Sure?",
    WARNING: "Details will Be Permanently Deleted!",
    CONFIRM: "Details Deleted!",
    SUCCESS: "Details Successfully Saved",
    ALERT: "Required!",
    EMAIL: "Resend verification email to ",
  },
  CLOCKPAGE: {
    HEADER: "Employee Clock Record",
    RECORDS: "No attendance records",
    RECORDS2:
      "The employee has to check-in first for attendance and clock records to appear.",
  },
  DEPARTMENTFORM: {
    HEADER: "Create Department",
    FORM: "Department Form",
    NAME: "Name",
    NAMES: "Enter Name",
    DESCRIPTION: "Description",
    DATECREATED: "Date Created",
    NAME1: "Enter Department Name",
    DESCRIPTION1: "Enter Descriptions",
  },
  DEVICEFORM: {
    HEADER: "Register Facial Device",
    FORM: "Facial Device Details",
    SERIALNUM: "Serial Number",
    NAME1: "Enter Facial Device Name",
    SERIALNUM1: "Enter Serial Number",
    DATEPURCHASED: "Date Of Purchase",
  },
  DEVICEEMPLOYEE: {
    HEADER: "Employee List",
    IN: "In",
    LIST: "List",
    STATUS: "No available employees",
    STATUS1: "No devices added",
    EMPLOYEECODE: "Employee Code",
    SYNC: "Sync",
    SAVE: "Save",
  },
  PROFILE: {
    USERPROFILE: "User Profile",
    SIGNOUT: "Sign Out",
    HI: "Hi, ",
    PROFILE: "Profile",
    ACCSETTINGS: "Account Settings",
    DEBUG: "DEBUG Mode",
  },
  SIDEBAR: {
    DASHBOARD: "Dashboard",
    COMPANY: "Company",
    SETTINGS: "Settings",
    HOME: "Home",
    EMPLOYEE: "Employee",
    DEPARTMENT: "Department",
    ATTENDANCE: "Attendance",
    LEAVE: "Leave",
    AWAY: "Away",
    DEVICE: "Device",
    REGISTRATION: "Profile",
    FACIAL: "Facial",
    MANAGEMENT: "Management",
    HOLIDAY: "Holidays",
    COMPREGISTRATION: "Company Registration",
  },
  LEAVE: {
    LEAVE: "Leave Details",
    DETAILS: "Details",
    ENTITLE: "Entitlement",
    AA: "Assignment",
    APPROVAL: "Application Approval",
    STAT: "Statistics",
    TYPE: "Leave Type",
    ENTITLEMENT: "Entitlement",
    APPLICATION: "Application Approval",
    STATISTICS: "Statistics",
    LEAVEDETAILS: "Create Leave Type",
    LEAVETYPE: "Leave Type Details",
    LEAVEAPPLICATION: "Leave Application",
    EMPLEAVE: "Employee Leave",
    APP: "Application",
    NEW: "Create New Application",
    APPLY: "Apply Leave",
    APPLYNEW: "Apply {leave}",
    HOVER: {
      EDIT: "Edit Leave",
      DELETE: "Delete Leave",
      NAVIGATEENT: "Navigate to Leave Entitlement",
      NAVIGATEASS: "Navigate to Leave Assignment",
      EDITENT: "Edit Entitlement",
      ASSIGNLEAVE: "Assign Leave",
    },
  },
  DETAILSTABLE: {
    TYPE: "LEAVE TYPE",
    DESC: "DESCRIPTION",
    UNIT: "LEAVE UNIT",
    COUNT: "DAY COUNT",
    PAID: "PAID LEAVE",
    ACT: "ACTIONS",
  },
  DETAILSFORM: {
    TYPE: "Leave Type",
    TYPE1: "Enter Leave Type",
    DESC: "Description",
    DESC1: "(200 characters max)",
    UNIT: "Leave Unit",
    COUNT: "Day Count",
    PAID: "Paid Leave?",
  },
  BUTTON: {
    CANCEL: "Cancel",
    OKAY: "OK",
    YES: "Yes",
    SEARCH: "Search",
    PRINT: "Print",
    APPLY: "Apply",
    UNVERIFIED: "Unverified",
    UPLOAD: "Upload",
    PHOTO: "Profile Picture",
    REJECT: "Reject",
    ADD: "Add",
  },
  LOGIN: {
    WELCOME: "Welcome to SmartID Corporate Login",
    NEW: "New Here?",
    REGISTER: "Register!",
    USERNAME: "Username",
    REQUIRED: "Username in the form of email is required",
    PASSWORD: "Password",
    REQUIRED2: "Password is required.",
    FORGOT: "Forgot Password ?",
    LOGIN: "Login",
  },
  REGISTER: {
    HAVE: " Already have an account?",
    ENTER: "Enter your details to create your account.",
    REQUIRED: "Name is required.",
    REQUIRED2: "Email is required and a valid email address.",
    REQUIRED3: "Work Email is required.",
    NUMBER: "Numbers only.",
    SIZE: "Company Size",
    WORK: "Work Email",
    EMPLOYER: "Employer",
  },
  NOTIFICATION: {
    USER: "User Notifications",
    MESSAGE: "Messages",
    EVENTS: "Events",
  },
  PERSONAL: {
    PERSONAL: "Personal",
    ACCOUNT: "Account",
    PERSONALPROFILE: "Personal Profile",
    GENERALDETAILS: "General Details",
    PERSONALDETAILS: "Personal Details",
    ACCOUNTDETAILS: "Account Details",
    EMERGENCYCONT: "Emergency Contacts",
    EDUDETAILS: "Education Details",
    PERSONALACC: "Personal Account",
  },
  FOOTER: {
    ABOUT: "About",
    CONTACT: "Contact",
  },
  JOB: {
    JOB: "Job",
    JOBSTATUS: "Job Status",
    POSITION: "Position",
    POSITION1: "Enter Your Position In Company",
    EMPTYPE: "Type",
    EMPTYPE1: "Enter Job Type",
    EFFDATE: "Effective Date",
    EFFDATE1: "Enter Effective Date",
    EMPGROUP: "Group",
    EMPGROUP1: "Enter Employee Group",
    PROBATION: "End of Probation",
    REMARK: "Remark",
    REMARK1: "Maximum 2000 Characters",
    EMPSTATUS: "Employment Status",
    STATUS: "Status",
    STATUS1: "Enter Employment Status",
    POSITIONS: "Job Positions",
    CREATE: "Create Job Positions",
    CODE: "Code",
  },
  SALARY: {
    SALARY: "Payroll",
    SALARY1: "Payroll Details",
    SALARY2: "Enter Payroll",
    SALARY3: "Basic Payroll",
    SALARY4: "Enter Basic Payroll",
    CURRENCY: "Currency",
    CURRENCY1: "Enter Currency",
    PAYMENT: "Payment Details",
    PAYMENT1: "Payment Type",
    PAYMENT2: "Enter Payment Type",
    BANK: "Bank",
    BANK1: "Enter Bank Name",
    ACCOUNT: "Bank Account",
    ACCOUNT1: "Enter Bank Account Number",
    METHOD: "Method",
    METHOD1: "Enter Method Type",
    STATUTORY: "Satutory Details",
    REMARK: "Remark",
    EPF: "Employer EPF Rate",
    EPF1: "Enter Employer EPF Rate",
    EPF2: "EPF Membership Number",
    EPF3: "Enter EPF Membership Number",
    EPF4: "Employee EPF Rate",
    EPF5: "Enter Employee EPF Rate",
    TAX: "Tax Reference Number",
    TAX1: "Enter Tax Reference Number",
    CHILD: "Child Relief",
    CHILD1: "Enter Child Relief",
    EIS: "EIS Contribution",
    EIS1: "Enter EIA Contribution",
    SOCSO: "SOCSO Category",
    SOCSO1: "Enter SOCSO Category",
    TAX2: "Tax Status",
    TAX3: "Enter Tax Status",
    ZAKAT: "Muslim Zakat Fund",
    ZAKAT1: "Enter Muslim Zakat Fund",
  },
  FAMILY: {
    FAMILY: "Family",
    MARITAL: "Marital Status",
    MARITAL1: "Enter Marital Status",
    NUMBER: "Number of Children",
    NUMBER1: "Enter Number of Children",
    SPOUSE: "Spouse Details",
    NATIONALITY: "Nationality",
    NATIONALITY1: "Enter Nationality",
    NRIC: "NRIC",
    NRIC1: "Enter NRIC",
    PASSPORT: "Passport",
    PASSPORT1: "Enter Passport",
    CHILDREN: "Children Details",
    DETAILS: "Family Details",
  },
  EDUCATION: {
    EDUCATION: "Education",
    EDUCATION1: "Education Details",
    SCHOOL: "School",
    SCHOOL1: "Enter Your School Name",
    DEGREE: "Level",
    DEGREE1: "Enter Your Degree Type",
    FIELD: "Course",
    FIELD1: "Enter Your Field of Study",
    GRADE: "Grade",
    GRADE1: "Enter Your Grade",
    FROM: "From",
    TO: "To",
    EDIT: "Edit Education Details",
    DELETE: "Delete Education Details",
  },
  SUMMARY: {
    SUMMARY: "Summary",
    SUMMARY1: "Enter Employee Summary",
  },
  HOLIDAY: {
    HOLIDAY: "Holiday",
    HOLIDAY1: "Enter Holiday",
    DAY: "Day",
    DATE: "Date",
    CREATE: "Create Holidays",
  },
  ACCOUNT: {
    CHANGEEMAIL: "Change Email",
    CHANGEEMAIL1: "New Email Address",
    CHANGEEMAIL2: "Enter New Email Address",
    CHANGEPASS: "Change Password",
    ENTERPASS1: "Enter Current Password",
    ENTERPASS2: "Enter New Password",
    ENTERPASS3: "Enter To Verify New Password",
    PASS1: "Current Password",
    PASS2: "Verify Password",
    PASS3: "New Password",
    ALERT:
      "To change current password, enter your desired new password along with existing password and click ",
    ALERT2: "To change current email, enter your desired new email and click ",
  },

  ENTITLEMENT: {
    CREATE: "Create Entilement",
    DETAILS: "Entitlement Details",
    TYPE: "Leave Type",
    DAYS: "Entitled Days",
    OVER: "Carried Over",
    VALID: "Valid Until",
    BALANCE: "Balance",
    BALANCE1: "Leave balance will automatically calculated",
    REASON: "Reason",
    REASON1: "Enter Reason",
    DAY: "Days",
    SESSION: "Session",
    ATTACHMENT: "Attachment",
    PURPOSE: "For Management Purpose",
    APPLICATION: "Application Date",
    SESSION1: "Enter Session",
    APPROVE: "Approve",
    PENDING: " Applications",
    ATTACHMENT1: "Choose File",
  },
  STATISTICS: {
    WEEK: "Week",
    PENDING: "Pending",
    APPROVED: "Approved",
    TOTAL: "Total",
    WEEK1: "Week :",
    NEW: "New Application",
    MONTH: "Month",
    YEAR: "Year",
  },
  BEACON: {
    BEACON: "Beacon List",
    BEACON1: "Beacon Name",
    BEACON2: "Enter Beacon Name",
    HEADER: "Register Beacon",
    DETAILS: "Beacon Details",
  },
  POSITIONS: {
    NAME: "Enter Position Name",
    CODE: "Enter Code",
    DETAILS: "Position Details",
  },
  DAILYATT: {
    IN: "Time In",
    SEEN: "Last Seen",
    TEMP: "Temperature",
    STATUS: "Status",
  },
  ...EMPLOYEE,
  ...COMPANY,
  ...LEAVE,
  ...COMMON,
};
