import Form from "@/modules/company/stores/group/schedule/form/emp_group_schedule_form.store";
import List from "@/modules/company/stores/group/schedule/list/emp_group_schedule_list.store";
import Delete from "./delete/delete.store";

export default {
  modules: {
    Form,
    List,
    Delete,
  },
};
