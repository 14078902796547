import CompanyLeaveTypeStore from "./types/company_leave_type.store";
import CompanyLeaveEntitlementStore from "@/modules/company/stores/leave/entitlement/company_leave_entitlement.store";
import CompanyLeaveApplication from "@/modules/company/stores/leave/application/company_leave_application.store";
import getCompanyLeaveStatistics from "@/modules/company/stores/leave/statistics/company_leave_statistics";
import CompanyLeaveAssignmentStore from "@/modules/company/stores/leave/assignment/company_leave_assignment.store";
import IndividualLeaveStore from "./individual/leave_individual.store";

export default {
  modules: {
    CompanyLeaveTypeStore,
    CompanyLeaveEntitlementStore,
    CompanyLeaveApplication,
    getCompanyLeaveStatistics,
    CompanyLeaveAssignmentStore,
    IndividualLeaveStore,
  },
};
