import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   * Fetch teacher monthly attendance export list
   *
   * @param context
   * @returns {*}
   */
  fetchEmployeeYearlyAttendanceExportList(context) {
    ApiService.setHeader();
    return ApiService.query("employee/attendance/admin/export/yearly", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": 150,
        date: state.filter.date ?? dayjs().format(),
      },
    }).then((res) => {
      console.log(res);
      context.commit("setEmployeeYearlyAttendanceExportList", res.data.data);
      context.commit(
        "setEmployeeYearlyAttendanceExportListPagination",
        res.data.meta.pagination
      );
    });
  },
};
