import ApiService from "@/core/services/api.service";

const state = {
  items: [],
  pagination: {},
  isBusy: false,
};

const actions = {
  fetchPersonalLeaveEntitlementList(context) {
    ApiService.setHeader();

    context.commit("setPersonalLeaveEntitlementListBusy", true);
    return ApiService.query("company/leave/personal/entitlement", {
      params: {
        "page[size]": 10,
        "page[number]": state.pagination.current_page ?? 1,
      },
    })
      .then((response) => {
        context.commit("setPersonalLeaveEntitlementList", response.data.data);
        context.commit(
          "setPersonalLeaveEntitlementListPagination",
          response.data.meta.pagination
        );
      })
      .finally(() =>
        context.commit("setPersonalLeaveEntitlementListBusy", false)
      );
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param data
   */
  setPersonalLeaveEntitlementList(state, data) {
    state.items = data;
  },
  /**
   *
   * @param state
   * @param pagination
   */
  setPersonalLeaveEntitlementListPagination(state, pagination) {
    state.pagination = pagination;
  },
  /**
   *
   * @param state
   * @param currentPage
   */
  setPersonalLeaveEntitlementListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Set Personal Leave entitlement Page busy state.
   *
   * @param state
   * @param isBusy
   */
  setPersonalLeaveEntitlementListBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
};

const getters = {
  getPersonalLeaveEntitlementList: (state) => state.items,
  getPersonalLeaveEntitlementListPagination: (state) => state.pagination,
  getPersonalLeaveEntitlementListBusy: (state) => state.isBusy,
};

export default { state, actions, mutations, getters };
