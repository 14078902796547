export default {
  /**
   *
   * @param state
   * @param count
   */
  setEmployeeDashboardCount(state, count) {
    console.log(count);

    state.employeeCount = count;
  },
};
