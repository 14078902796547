const FacialDevice = () =>
  import("@/modules/device/components/facial/fd/FacialDevicePage");

export default [
  {
    path: "/device/facial",
    name: "facialDevice",
    component: FacialDevice,
  },
];
