import VALIDATION from "./validation.en";

export default {
  COMMON: {
    TOOLTIP: {
      REFRESH: "Refresh",
    },

    Actions: {
      Close: "Close",
      Search: "Search",
    },

    Upload: {
      Image: {
        Success:
          "Successfully uploaded image. Can now be used in facial device recognition",
        Success_Title: "Image uploaded",
      },
    },

    Download: {
      Pending: "Downloading file",
      Success: "Download success",
      Failed: "Download failed",
    },

    Table: {
      Actions: {
        Base: "Actions",
        Reset_Filters: "Reset Filters",
        Export: "Export - {type}",
        Export_Excel: "Export - Excel",
        Export_PDF: "Export - Excel",
      },
    },
  },
  ...VALIDATION,
};
