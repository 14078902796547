import ApiService from "@/core/services/api.service";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";

const state = {
  isBusy: false,
  employees: [],
  meta: {},
  errors: null,
  filters: {
    first_name: "",
    last_name: "",
    code: "",
    role: null,
    base: "",
  },
};

const actions = {
  /**
   * @param context
   * @param typeId
   * @returns {Promise<unknown>}
   */
  async getEmployeeList(context, typeId = 1) {
    ApiService.setHeader();
    console.log("Meta", state.meta);

    context.commit("setEmployeeListBusy", true);
    const response = await ApiService.query("employee/record", {
      params: {
        "page[number]": state.meta.current_page ?? 1,
        "page[size]": 15,
        "filter[base]": state.filters?.base,
        type_id: typeId,
      },
    });

    try {
      context.commit("setEmployeeList", response.data);
      context.commit(
        "setEmployeeListPagination",
        response.data.meta.pagination
      );

      return response;
    } catch (reason) {
      context.commit("setEmployeeListError", reason);
    } finally {
      context.commit("setEmployeeListBusy", false);
    }
  },

  /**
   * Download report.
   *
   * @param context
   * @param payload
   * @returns {Promise<AxiosResponse<*>>}
   */
  async downloadEmployeeListReport(context, payload) {
    ApiService.setHeader();

    try {
      const response = await ApiService.query("employee/record/report", {
        responseType: "blob",
        params: {
          "page[number]": state.meta.current_page ?? 1,
          "filter[first_name]": state.filters?.first_name,
          type_id: payload.typeId ?? 1,
        },
      });

      AppReportDownloadService.processDownload(
        response.data,
        "Employee List - All"
      );

      return response;
    } catch (error) {
      console.error(error);
      throw Error("test");
    }
  },

  /**
   * @param context
   */
  emptyEmployeeList(context) {
    context.commit("clearEmployeeList");
  },

  /**
   * testing delete
   * @param context
   * @param employeeId
   */
  deleteEmployee(context, employeeId) {
    console.log("Id is", employeeId);
    ApiService.setHeader();
    return ApiService.delete("employee/record", {
      params: {
        employee_id: employeeId,
      },
    }).then((response) => {
      console.log(response);
    });
  },

  /**
   * Filters bro.
   *
   * @param context
   * @param payload
   */
  changeEmployeeListFilters(context, payload) {
    context.commit("changeEmployeeListFilters", payload.filters);
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeEmployeeListNameFilter(context, payload) {
    const filters = state.filters;
    filters.first_name = payload.first_name;

    context.commit("changeEmployeeListFilters", filters);
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeEmployeeListBaseFilter(context, payload) {
    const filters = state.filters;
    filters.base = payload.base;

    context.commit("setEmployeeListFilters", filters);
  },
};

const mutations = {
  /**
   * Set Employee List.
   *
   * @param state
   * @param response
   */
  setEmployeeList(state, response) {
    console.log("Response data", response);
    state.employees = response.data;
  },

  /**
   * Set busu state
   *
   * @param state
   * @param busy
   */
  setEmployeeListBusy(state, busy) {
    state.isBusy = busy;
  },

  /**
   * Set List Error.
   *
   * @param state
   * @param error
   */
  setEmployeeListError(state, error) {
    console.error(error);
    state.errors = error;
  },

  /**
   * Set List Pagination
   *
   * @param state
   * @param pagination
   */
  setEmployeeListPagination(state, pagination) {
    console.log(pagination);
    state.meta = pagination;
  },

  /**
   * Change page.
   *
   * @param context
   * @param page
   */
  setEmptyEmployeeListPage(context, page) {
    state.meta.current_page = page;
  },

  /**
   * Clear Employee List.
   *
   * @param state
   */
  clearEmployeeList(state) {
    state.items = [];
    state.response = {};
  },

  /**
   * Set filters.
   *
   * @param state
   * @param filters
   */
  setEmployeeListFilters(state, filters) {
    state.filters = filters;
  },
};

const getters = {
  getEmployeeListItems: (state) => state.employees,
  getEmployeeListItemsBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
