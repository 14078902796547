import ApiService from "@/core/services/api.service";

const state = {
  id: null,
  employee_id: null,
  leave: {},
  isSaved: false,
  form: {}, // Note, this is not the component's form!
  isBusy: false,
};

const actions = {
  /**
   * Submit the employee's entitlement form
   *
   * @param context
   * @param form
   */
  async submitEntitlementForm(context, form) {
    try {
      context.commit("setAssignEmployeeToLeaveEntitlementIsBusy", true);

      ApiService.setHeader();
      return await ApiService.post("company/leave/employee/entitlement", form);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      context.commit("setAssignEmployeeToLeaveEntitlementIsBusy", false);
    }
  },

  /**
   * Get the form from API.
   *
   * @param context
   * @param payload is the id Employee Assigned Leave
   */
  async fetchEntitlementForm(context, payload) {
    try {
      ApiService.setHeader();
      const response = await ApiService.query(
        "company/leave/employee/entitlement/form",
        {
          params: {
            id: payload,
          },
        }
      );

      context.commit("setEntitlementForm", response.data.data);
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   *
   * @param context
   * @param isSaved
   */
  assignEntitlementFormIsSaved(context, isSaved) {
    context.commit("setAssignEntitlementFormIsSaved", isSaved);
  },

  /**
   *
   * @param context
   * @param payload
   */
  assignEmployeeToLeaveEntitlement(context, payload) {
    context.commit("setAssignEmployeeToLeaveEntitlement", payload);
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param form
   */
  setEntitlementForm(state, form) {
    console.log("Current Form vs New Form", state.form, form);
    state.form = form;
  },

  /**
   *
   * @param state
   * @param payload
   */
  setAssignEmployeeToLeaveEntitlement(state, payload) {
    state.id = payload.id;
    state.employee_id = payload.employee_id;
  },

  setAssignEmployeeToLeaveEntitlementIsBusy(state, isBusy) {
    state.isBusy = isBusy;
    console.log("State after: ", state);
  },

  /**
   *
   * @param state
   * @param isSaved
   */
  setAssignEntitlementFormIsSaved(state, isSaved) {
    state.isSaved = isSaved;
  },
};

const getters = {
  getAssignEmployeeToLeaveEntitlement: (state) => {
    return {
      id: state.id,
      employee_id: state.employee_id,
    };
  },
  getAssignEntitlementFormIsSaved: (state) => state.isSaved,
  getEntitlementForm: (state) => state.form,
  getAssignEmployeeToLeaveEntitlementIsBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
