import ApiService from "@/core/services/api.service";

const state = {
  form: {
    name: "Haha"
  },
};

const actions = {
  /**
   * Creates new holiday.
   *
   * @param context
   * @param form
   * @returns {*}
   */
  saveCompanyHoliday(context, form) {
    ApiService.setHeader();
    return ApiService.post("holiday/record", form);
  },

  /**
   * Remove holiday.
   *
   * @param context
   * @param id
   * @returns {*}
   */
  deleteCompanyHoliday(context, id) {
    console.log("Holiday ID:", id);
    ApiService.setHeader();
    return ApiService.delete("holiday/record", {
      data: {
        holiday_id: id,
      },
    });
  },

  /**
   * Update the company holiday
   *
   * @param context
   * @param payload has a form
   */
  updateCompanyHoliday(context, payload) {
    ApiService.setHeader();
    return ApiService.post("holiday/record/update", payload.form);
  },

  /**
   * Change company holiday form.
   *
   * @param context
   * @param payload
   */
  changeCompanyHolidayForm(context, payload) {
    context.commit("setCompanyHolidayForm", payload.form);
  },
};

const mutations = {
  setCompanyHolidayForm(state, form) {
    console.log("Form is: ", form);

    state.form = form;
    console.log("Form: ", state.form);
  },
};

const getters = {
  getCompanyHolidayForm: (state) => state.form,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
