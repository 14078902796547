import ApiService from "@/core/services/api.service";

const state = {
  items: [],
  pagination: {},
};

const actions = {
  /**
   * Fetch the employee leave entitlement
   *
   * @param context
   * @param payload is the Employee ID
   */
  fetchEmployeeLeaveAssignmentList(context, payload) {
    ApiService.setHeader();
    return ApiService.query("company/leave/employee/assignment", {
      params: {
        employee_id: payload,
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": 30,
      },
    }).then((response) => {
      context.commit("setEmployeeLeaveAssignmentList", response.data.data);
      context.commit(
        "setEmployeeLeaveAssignmentListPagination",
        response.data.meta.pagination
      );
    });
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param data
   */
  setEmployeeLeaveAssignmentList(state, data) {
    state.items = data;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setEmployeeLeaveAssignmentListPagination(state, pagination) {
    console.log("Pagination before and after", state.pagination, pagination);
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setEmployeeLeaveAssignmentListPage(state, currentPage) {
    console.log("Pagination before and after", state.pagination, currentPage);
    state.pagination.current_page = currentPage;
  },
};

const getters = {
  getEmployeeLeaveAssignment: (state) => state.items,
  getEmployeeLeaveAssignmentPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
