import state from "@/modules/personal/stores/leave/application/list/state";
import mutations from "@/modules/personal/stores/leave/application/list/mutations";
import actions from "@/modules/personal/stores/leave/application/list/actions";

const getters = {
  getPersonalLeaveApplicationList: (state) => state.items,
  getPersonalLeaveApplicationListPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
