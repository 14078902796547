import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getEmployeeYearlyAttendanceList: (state) => state.items,
  getEmployeeYearlyAttendancePagination: (state) => state.pagination,
  getEmployeeYearlyAttendanceListDate: (state) => state.filter.date,
  getEmployeeYearlyAttendanceListBusy: (state) => state.isBusy,
};

export default {
  actions,
  state,
  mutations,
  getters,
};
