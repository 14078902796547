export default {
  /**
   * Set All Company Leave
   *
   * @param state
   * @param data
   */
  setAllCompanyLeaveApplicationList(state, data) {
    state.items = data;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setAllCompanyLeaveApplicationPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setAllCompanyLeaveApplicationPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Set Search Query
   *
   * @param state
   * @param search
   */
  setAllCompanyLeaveApplicationSearch(state, search) {
    state.filter.search = search;
  },

  /**
   *
   * @param state
   * @param filters
   */
  setAllCompanyLeaveApplicationFilters(state, filters) {
    console.log(filters);

    state.filters = filters;
    console.log("Filters after: ", state.filters);
  },

  /**
   * Set List Fetch Busy state.
   *
   * @param state
   * @param busy
   */
  setAllCompanyLeaveApplicationListBusy(state, busy) {
    state.isBusy = busy;
  },
};
