import ApiService from "@/core/services/api.service";
import Vue from "vue";
import dayjs from "dayjs";

const state = {
  items: [],
  meta: {},
  isBusy: false,
  filter: {
    date: dayjs().format(),
  },
};

const actions = {
  /**
   * Fetch company holidays.
   *
   * @param context
   */
  fetchCompanyHolidays(context) {
    state.isBusy = true;
    ApiService.setHeader();
    Vue.axios
      .get("holiday/record", {
        params: {
          "page[number]": state.meta.current_page ?? 1,
          date: state.filter.date,
        },
      })
      .then((response) => {
        console.log("Received from holiday/record", response);
        context.commit("setCompanyHolidayList", response.data.data);
        context.commit(
          "setCompanyHolidayListPagination",
          response.data.meta.pagination
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => (state.isBusy = false));
  },

  /**
   * Change date filter via mutation.
   *
   * @param context
   * @param payload
   */
  changeHolidayDateFilter(context, payload) {
    console.log("To change year: ", payload.date);

    const objectSupport = require("dayjs/plugin/objectSupport");
    dayjs.extend(objectSupport);

    const date = dayjs({
      year: payload.date.year,
    });

    console.log("Formatted date is ", date.format());

    context.commit("setHolidayDateFilter", date.format());
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param data
   */
  setCompanyHolidayList(state, data) {
    state.items = data;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setCompanyHolidayListPagination(state, pagination) {
    console.log("Pagination", pagination);
    state.meta = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setCompanyHolidayListPaginationPage(state, currentPage) {
    state.meta.current_page = currentPage;
  },

  /**
   * Set date filter.
   *
   * @param state
   * @param date
   */
  setHolidayDateFilter(state, date) {
    state.filter.date = date;
  },
};

const getters = {
  getCompanyHolidayList: (state) => state.items,
  getCompanyHolidayListPagination: (state) => state.meta,
  getCompanyHolidayListBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
