import ApiService from "@/core/services/api.service";
import state from "./states";
import dayjs from "dayjs";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";

export default {
  /**
   * Fetch.
   *
   * @param context
   */
  async fetchEmployeeDailyAttendance(context) {
    try {
      ApiService.setHeader();
      context.commit("setEmployeeDailyAttendanceBusy", true);

      console.log("Daily Date ", dayjs().format());

      const response = await ApiService.query("employee/attendance", {
        params: {
          "page[size]": state.size,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[base]": state.filers?.base,
          date: dayjs().format(),
        },
      });

      context.commit("setEmployeeDailyAttendance", response.data.data);
      context.commit(
        "setEmployeeDailyAttendancePagination",
        response.data.meta.pagination
      );
    } catch (e) {
      console.error(e);
      throw Error("Failed");
    } finally {
      context.commit("setEmployeeDailyAttendanceBusy", false);
    }
  },

  /**
   * Change daily attendance name filter.
   *
   * @param context
   * @param payload
   */
  changeEmployeeDailyAttendanceBaseFilter(context, payload) {
    const filters = state.filers;

    filters.base = payload.base;

    context.commit("setEmployeeDailyAttendanceFilters", filters);
  },

  /**
   * Download
   *
   * @param context
   * @param payload
   * @returns {Promise<void>}
   */
  async downloadEmployeeDailyReport(context, payload) {
    try {
      ApiService.setHeader();
      const response = await ApiService.query(
        "employee/attendance/report/daily",
        {
          responseType: "blob",
          params: {
            type: payload.type ?? 1,
          },
        }
      );

      AppReportDownloadService.processDownload(
        response.data,
        "Employee Attendance"
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};
