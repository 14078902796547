import ApiService from "@/core/services/api.service";

const state = {
  items: [],
  pagination: {},
  filters: {
    base: null,
  },
};

const actions = {
  /**
   * Fetch employee list (for leave).
   *
   * @param context
   * @returns {Promise<*>}
   */
  async fetchEmployeeListForLeave(context) {
    try {
      ApiService.setHeader();
      const response = await ApiService.query("employee/record", {
        params: {
          "page[size]": 30,
          "page[number]": state.pagination.current_page ?? 1,
          type_id: 2,
          "filter[base]": state.filters?.base,
        },
      });

      context.commit("setEmployeeListForLeave", response.data.data);
      context.commit(
        "setEmployeeListForLeavePagination",
        response.data.meta.pagination
      );

      return response.data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeEmployeeListForLeaveBaseFilter(context, payload) {
    const filters = state.filters;
    filters.base = payload.base;

    context.commit("setEmployeeListForLeaveFilters", filters);
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param data
   */
  setEmployeeListForLeave(state, data) {
    state.items = data;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setEmployeeListForLeavePagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setEmployeeListForLeavePage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Set filters.
   *
   * @param state
   * @param filters
   */
  setEmployeeListForLeaveFilters(state, filters) {
    state.filters = filters;
  },
};

const getters = {
  getCompanyLeaveEntitlementList: (state) => state.items,
  getCompanyLeaveEntitlementListPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
