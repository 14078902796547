export default {
  EDUCATION: {
    EDUCATION: "Education",
    EDUCATION1: "Education Details",
    SCHOOL: "School",
    SCHOOL1: "Enter Your School Name",
    DEGREE: "Degree",
    DEGREE1: "Enter Your Degree Type",
    FIELD: "Field of Study",
    FIELD1: "Enter Your Field of Study",
    GRADE: "Grade",
    GRADE1: "Enter Your Grade",
    FROM: "From",
    TO: "To",
    EDIT: "Edit Education Details",
    DELETE: "Delete Education Details",

    MODAL: {
      TITLE: "Education Details",
    },

    ACTIONS: {
      DELETE: {
        CANCELLED: "Delete cancelled",
        CONFIRM: "Delete {name} detail?",
        SUCCESS: "Deleted {name} detail"
      },
    },
  },
};
