import PersonalMenu from "@/modules/core/store/aside/menu/personal.menu";
import ManagementMenu from "@/modules/core/store/aside/menu/management.menu";
import SettingsMenu from "@/modules/core/store/aside/menu/settings.menu";
import UserService from "@/modules/auth/services/user.service";

const state = {
  items: [
    // Personal
    ...PersonalMenu,
    // Management
    ...ManagementMenu,
    ...SettingsMenu,
    {
      text: "SIDEBAR.DEVICE",
      type: "menu-nav-parent",
      guard: {
        permissions: ["manage.employee"],
      },
      url: {
        path: "device/facial",
      },
      icon: "/media/svg/icons/Devices/Generator.svg",
      children: [
        {
          text: "DEVICE.FACIAL.PAGE.HEADER",
          type: "menu-nav",
          url: {
            path: "/device/facial",
          },
          guard: {
            permissions: ["manage.employee"],
          },
          icon: "/media/svg/icons/Home/Building.svg",
        },
      ],
    },
  ],
  permissions: null,
  isBusy: true,
};

const actions = {
  async generateMenuItems(context) {
    return await UserService.getPermissions().then((value) => {
      console.log(value);
      context.commit("setGeneratedMenu", value);
      return value;
    });
  },
};

const mutations = {
  setGeneratedMenu(state, value) {
    value.onsuccess = () => {
      console.log("Setting permissions", value.result);
      state.permissions = value.result;
      state.isBusy = false;
      console.log(state.permissions);
    };
  },
};

const getters = {
  getUserPermissions: (state) => state.permissions,
  getSuperAdminPermission: (state) => {
    let isSuper = false;

    if (state.permissions === null) return isSuper;

    const similar = state.permissions.filter((item) => {
      return item.name === "corporate.admin.super";
    });
    console.log("Is Super", similar);
    if (similar.length > 0) isSuper = true;
    return isSuper;
  },
  getAdminPermission: (state) => {
    let isAdmin = false;

    if (state.permissions === null) return isAdmin;

    const similar = state.permissions.filter((item) => {
      return item.name === "corporate.admin.standard";
    });
    console.log("Is Admin", similar);
    if (similar.length > 0) isAdmin = true;
    return isAdmin;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
