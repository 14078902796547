export default {
  /**
   * Set personal leave entitlement form.
   *
   * @param state
   * @param form
   */
  setPersonalLeaveEntitlementForm(state, form) {
    console.log("Form : ", form);

    state.form = form;
  },

  /**
   * Set personal leave entitlement form busy.
   *
   * @param state
   * @param isBusy
   */
  setPersonalLeaveEntitlementFormBusy(state, isBusy) {
    console.log("isBusy mode : ", isBusy);

    state.isBusy = isBusy;
  },
};
